import { FunctionComponent } from "react"
import styled from "styled-components"

import { MEDIA_BREAKPOINT } from "shared/constants/media-breakpoints"
import { MobileLayoutStyled, TableLayoutBody, TableLayoutColumn, TableLayoutHeader } from "./layout.style"

type IElements = Record<string, JSX.Element | null>

export const MobileLayout: FunctionComponent<{ elements: IElements }> = ({ elements }) => {
  return (
    <MobileLayoutStyled>
      <TableLayoutHeader>
        {elements.pair}
        {elements.counter}
      </TableLayoutHeader>
      <TableLayoutBody>
        <TableLayoutColumn>
          {elements.company}
          {elements.client}
        </TableLayoutColumn>
        <TableLayoutColumn>
          {elements.offerStatus}
          {elements.offerStep}
        </TableLayoutColumn>
      </TableLayoutBody>
    </MobileLayoutStyled>
  )
}

export const DesktopLayout = styled.div`
  display: grid;
  gap: 24px;
  padding: 12px;
  grid-template-columns: 1fr 1fr 0.75fr 200px 40px;

  &.is-corporate-layout {
    grid-template-columns: 1fr 1fr 1fr 0.75fr 200px 40px;
  }

  @media ${MEDIA_BREAKPOINT.MD} {
    padding: 12px 16px;
  }

  @media ${MEDIA_BREAKPOINT.XL} {
    padding: 12px 24px;
  }
`