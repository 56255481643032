import { DealsStatusIcon } from 'pages/my-deals/ui/DealsStatusIcon';
import { FC, ReactNode } from 'react';

interface IProps {
  name: string | number;
  label: ReactNode;
}

export const OfferStatusOption: FC<IProps> = (props) => {
  const { name, label } = props;

  return (
    <>
      <DealsStatusIcon status={name.toString()} hideDefault size={14} />
      {label}
    </>
  );
};
