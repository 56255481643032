import React from 'react';
import { TagProps, TagType } from 'antd/es/tag';
import { Tag } from 'antd';
import styled from 'styled-components';
import { CloseOutlined } from '@ant-design/icons';
interface Props extends TagProps {
  children: React.ReactNode;
}
const GreyTag: React.FC<Props> = ({ children, ...props }) => {
  return (
    <TagWrapper>
      <Tag
        onClick={props.onClose}
        {...props}
        color="rgba(98, 98, 98, 0.88)"
        closeIcon={<CloseOutlinedStyled />}
      >
        <TagText>{children}</TagText>
      </Tag>
    </TagWrapper>
  );
};

export default GreyTag;

const TagText = styled.span`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  color: #DEDEE3;
`;

const TagWrapper = styled.span`
  cursor: pointer;
  .ant-tag.ant-tag-has-color {
      background: #FFFFFF0F !important;
  }
`;

const CloseOutlinedStyled = styled(CloseOutlined)`
  font-size: 12px;
  svg {
    color: #DEDEE3;
  }
`;
