import Styles from './index.module.css';
import React, { useRef, useState } from 'react';
import { Spin } from 'antd';
import styled from 'styled-components';
import { ChatListMessages } from './list-messages';

interface IChatContext {
  setPrevHeight: (value: number) => void;
  containerRef: React.RefObject<HTMLDivElement> | null;
  prevHeight: number;
}

export const MessageContainerContext = React.createContext<IChatContext>({
  setPrevHeight: () => {},
  containerRef: null,
  prevHeight: 0,
});

type TChatMessageContainer = {
  setIsFetchingMessages: (value: boolean) => void;
  isFetchingMessages: boolean;
  messages: any[];
  isAdmin: boolean;
};

const ChatMessageContainer = ({
  isAdmin,
  setIsFetchingMessages,
  isFetchingMessages,
  messages,
}: TChatMessageContainer): JSX.Element => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [prevHeight, setPrevHeight] = useState(0);

  const scrollHandler = (e: any) => {
    if (e.target.scrollTop === 0) {
      setIsFetchingMessages(true);
    }
  };

  const renderListMessage = () => {
    if (isFetchingMessages) {
      return (
        <SpinnWrapper>
          <Spin size={'large'} />
        </SpinnWrapper>
      );
    } else {
      return <ChatListMessages messages={messages} />;
    }
  };

  return (
    <MessageContainerContext.Provider
      value={{ setPrevHeight, containerRef, prevHeight }}
    >
      <MessageContainerStyled
        $isAdmin={isAdmin}
        ref={containerRef}
        onScroll={scrollHandler}
        className={Styles.container}
      >
        {renderListMessage()}
      </MessageContainerStyled>
    </MessageContainerContext.Provider>
  );
};

const MessageContainerStyled = styled.div<{ $isAdmin: boolean }>`
  ${(props) =>
    props.$isAdmin &&
    `
        min-height: 38vh;
  `}
`;

const SpinnWrapper = styled.div`
  display: flex;
  justify-content: center;

  .ant-spin-dot-item {
    background: #1668dc;
  }
`;

export { ChatMessageContainer };
