import * as React from 'react';
import {
  NotificationAsideButtonStyled,
  NotificationAsideContainerStyled, NotificationAsideContentStyled,
  NotificationAsideFooterStyled,
  NotificationAsideHeaderStyled,
  NotificationAsideLinkStyled,
  NotificationAsideSettingsButtonStyled,
  NotificationAsideSubHeaderStyled,
  NotificationAsideTitleStyled
} from './NotificationAside.style';
import { useNotificationAsideState } from '../../model/useNotificationAsideState';
import { SettingOutlined } from '@ant-design/icons';
import { NotificationAsideList } from '../NotificationAsideList/NotificationAsideList';
import { useTranslation } from 'react-i18next';
import { NotificationAsideTabs } from './NotificationAsideTabs/NotificationAsideTabs';
import { useEffect } from 'react';

export const NotificationAside = () => {

  const { t } = useTranslation();

  const activeTab = useNotificationAsideState((state) => state.activeTab)

  const notifications = useNotificationAsideState((state) => state.notifications)
  const setNotifications = useNotificationAsideState((state) => state.setNotifications)
  const readAllNotifications = useNotificationAsideState((state) => state.readAllNotifications)

  const readAllClickHandler = () => {
    readAllNotifications()
  }

  useEffect(() => {
    if (activeTab === 'all') {
      setNotifications([
        {
          field: 'is_read',
          operator: '=',
          value: false,
        }
      ])
    }
    if (activeTab === 'my_deals' || activeTab === 'corporate') {
      setNotifications([
        {
          field: 'is_read',
          operator: '=',
          value: false,
        },
        {
          field: 'type',
          operator: '=',
          value: activeTab,
        },
      ])
    }
  }, [activeTab, notifications.length]);

  return (
    <NotificationAsideContainerStyled>
      <NotificationAsideHeaderStyled>
        <NotificationAsideTitleStyled>{t('Уведомления')}</NotificationAsideTitleStyled>
        <NotificationAsideSettingsButtonStyled to={"/notifications-settings"}>
          <SettingOutlined color={'rgba(22, 119, 255, 1)'}/>
          <span>{t('Настройка')}</span>
        </NotificationAsideSettingsButtonStyled>
      </NotificationAsideHeaderStyled>
      <NotificationAsideSubHeaderStyled>
        <NotificationAsideTabs />
      </NotificationAsideSubHeaderStyled>
      <NotificationAsideContentStyled>
        <NotificationAsideList />
      </NotificationAsideContentStyled>
      {!!notifications.length &&
        <NotificationAsideFooterStyled>
          <NotificationAsideLinkStyled to={"/notifications"}>{t('Смотреть все уведомления')}</NotificationAsideLinkStyled>
          <NotificationAsideButtonStyled onClick={readAllClickHandler} role={"button"}>{t('Прочитать всё')}</NotificationAsideButtonStyled>
        </NotificationAsideFooterStyled>
      }
    </NotificationAsideContainerStyled>
  )
}
