import {
  ChatListEmptyTitleStyled,
  ChatListItemsEmptyStyled,
} from './assets/styles';
import ChatListEmpty from './assets/ChatListEmpty.svg';
import { useTranslation } from 'react-i18next';

const ChatListItemsEmpty = () => {
  const { t } = useTranslation();
  return (
    <ChatListItemsEmptyStyled>
      <img src={ChatListEmpty} alt={'ChatListEmpty'} />
      <ChatListEmptyTitleStyled>
        {t('У вас нет чатов')}
      </ChatListEmptyTitleStyled>
    </ChatListItemsEmptyStyled>
  );
};

export default ChatListItemsEmpty;
