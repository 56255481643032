import { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';

import { ModalConfirmReceiptOfCash } from './ModalConfirmReceiptOfCash/ModalConfirmReceiptOfCash';
import { ModalCancelTransaction } from './ModalCancelTransaction/ModalCancelTransaction';
import { ModalShowPointOnMap } from './ModalShowPointOnMap/ModalShowPointOnMap';
import { ModalOpenDispute } from './ModalOpenDispute/ModalOpenDispute';
import { ModalConfirmPayment } from './ModalConfirmPayment/ModalConfirmPayment';
import { useModalState } from '../model/useModalState';
import { useResize } from 'shared/hooks/use-resize';
import { ModalKeys } from '../model/enums';
import { ModalUserMenu } from './ModalUserMenu';
import { useWindowScrollLock } from 'shared/hooks/useWindowScrollLock';

export const RenderModal = ({
  currentModalKey,
  isShowHeader,
}: {
  currentModalKey: ModalKeys;
  isShowHeader?: boolean;
}) => {
  const isModalOpen = useModalState((state) =>
    state.isModalOpen(currentModalKey)
  );
  const { isScreen991, isScreen480, isScreen720 } = useResize();

  const ref = useRef<HTMLBodyElement | null>();
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    ref.current = document.querySelector('body');
    setMounted(true);
  }, []);

  useWindowScrollLock(isModalOpen);

  const modals = {
    [ModalKeys.ModalCancelTransaction]: isModalOpen && (
      <ModalCancelTransaction />
    ),
    [ModalKeys.ModalConfirmReceiptOfCash]: isModalOpen && (
      <ModalConfirmReceiptOfCash />
    ),
    [ModalKeys.ModalOpenDispute]: isModalOpen && <ModalOpenDispute />,
    [ModalKeys.ModalShowPointOnMap]: isModalOpen && <ModalShowPointOnMap />,
    [ModalKeys.ModalConfirmPayment]: isModalOpen && <ModalConfirmPayment />,
    [ModalKeys.ModalUserMenu]: isModalOpen && <ModalUserMenu />,
    // temporary hack
    [ModalKeys.ModalDealsFilters]: isModalOpen && null,
  };

  /* TODO: refactor; 
   * Eugene.B: На данный момент модалка рендерится в body.
   * ModalWrapper при этом div модалки не включает
   */
  return mounted && ref.current
    ? createPortal(
        <ModalWrapperStyled
          id="modal-container"
          $isScreen980={isScreen991}
          $isScreen720={isScreen720}
          $isScreen425={isScreen480}
          $isShowHeader={isShowHeader}
        >
          {modals[currentModalKey]}
        </ModalWrapperStyled>,
        ref.current
      )
    : null;
};

interface IModalWrapperProps {
  $isScreen980?: boolean;
  $isScreen720?: boolean;
  $isScreen425?: boolean;
  $isShowHeader?: boolean;
}

const ModalWrapperStyled = styled.div<IModalWrapperProps>`
  overflow-y: hidden;
  width: calc(100% - 15px);

  ${({ $isShowHeader }) =>
    $isShowHeader &&
    `
    .ant-modal {
      padding-bottom: 0;
      top: 72px;
    }
    .ant-modal-mask {
      top: 72px;
    }
  `}

  .ant-modal-title {
    margin-bottom: 24px !important;
    font-weight: 700 !important;
    color: #fbfbff !important;
    background: transparent;
  }

  .ant-modal {
    min-width: ${({
      $isScreen980,
      $isScreen425,
      $isScreen720,
      $isShowHeader,
    }) => {
      if ($isShowHeader && !$isScreen720) {
        return '100%';
      }
      if (!$isScreen980 && $isScreen425) {
        return 'calc(100% - 32px);';
      }
      if (!$isScreen425) {
        return '100%';
      }
    }};
    margin-top: 0;
    margin-bottom: 0;
  }

  .ant-modal-content {
    min-height: ${({
      $isScreen980,
      $isScreen425,
      $isScreen720,
      $isShowHeader,
    }) => {
      if ($isShowHeader && !$isScreen720) {
        return 'calc(100vh - 72px)';
      }
      if (!$isScreen980 && $isScreen425) {
        return 'calc(100vh - 32px)';
      }
      if (!$isScreen425) {
        return '100vh';
      }
    }};
  }
  
  .ant-modal-content {
    border-radius: ${({ $isScreen720 }) => {
      if (!$isScreen720) {
        return '0';
      }
    }};
  }
`;
