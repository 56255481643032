import Icon, {
  ClockCircleOutlined, CheckCircleOutlined, ExclamationCircleOutlined, CloseCircleOutlined,
} from '@ant-design/icons';

import { GarantStatus, GoToExchangeStatus } from 'assets/img/icons/icon';
import { DEAL_STATUS } from 'shared/constants/enums';

interface DealStatusProps {
  status: string
  size?: number
  hideDefault?: boolean
}

export const DealsStatusIcon = (props: DealStatusProps) => {
  const { status, size = 16, hideDefault } = props

  if (status === DEAL_STATUS.pending) {
    return <ClockCircleOutlined style={{ color: '#85A5FF', fontSize: size }} />
  }

  if (status === DEAL_STATUS.accepted) {
    return <CheckCircleOutlined style={{ color: '#4096FF', fontSize: size }} />
  }

  if (status === DEAL_STATUS.warrantyCollateral) {
    return <Icon component={GarantStatus} style={{ color: '#FFC069', fontSize: size }} />
  }

  if (status === DEAL_STATUS.waitingForFiatPayment) {
    return <ClockCircleOutlined style={{ color: '#69b1ff', fontSize: size }} />
  }

  if (status === DEAL_STATUS.ready) {
    return <Icon component={GoToExchangeStatus} style={{ color: '#69B1FF', fontSize: size }} />
  }

  if (status === DEAL_STATUS.completed) {
    return <CheckCircleOutlined style={{ color: '#36CFC9', fontSize: size }} />
  }

  if (status === DEAL_STATUS.expired) {
    return <ClockCircleOutlined style={{ color: '#FF7875', fontSize: size }} />
  }

  if (status === DEAL_STATUS.onArgument) {
    return <ExclamationCircleOutlined style={{ color: '#FFC069', fontSize: size }} />
  }

  if (status === DEAL_STATUS.cancelled) {
    return <CloseCircleOutlined style={{ color: '#FF7875', fontSize: size }} />
  }

  return hideDefault
    ? null
    : (
      <ClockCircleOutlined style={{ color: '#a3a3a3', fontSize: size }} />
    )
}
