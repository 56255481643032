import { FunctionComponent } from 'react';

import { TChatListTranslations } from '../../hooks/useChatListItemTranslations';
import {
  ChatListIconBlock,
  ChatListIconColumn,
  ChatListIconColumnItems,
  ChatListIconColumnSubTitle,
  ChatListIconColumnTitle,
  ChatListIconDot,
} from '../../ChatListItem.style';
import notClsx from 'shared/helpers/not-clsx';

type TChatListItemOfferStatus = {
  isActiveStatus?: boolean
  translations: TChatListTranslations
};

const ChatListItemOfferStatus: FunctionComponent<TChatListItemOfferStatus> = ({ isActiveStatus, translations }: TChatListItemOfferStatus) => {
  const renderStatus = isActiveStatus
    ? translations.active
    : translations.inactive

  return (
    <ChatListIconBlock>
      <ChatListIconColumn>
        <ChatListIconDot className={notClsx(isActiveStatus && 'is-active')} />
      </ChatListIconColumn>
      <ChatListIconColumnItems>
        <ChatListIconColumnTitle>{translations.offer}</ChatListIconColumnTitle>
        <ChatListIconColumnSubTitle>{renderStatus}</ChatListIconColumnSubTitle>
      </ChatListIconColumnItems>
    </ChatListIconBlock>
  );
};

export default ChatListItemOfferStatus;
