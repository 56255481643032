import { styled } from "styled-components";
import { Modal } from "antd";

export const ModalStyled = styled(Modal)`
  height: 600px;
  // !important убрать не получится тк модалка
  width: fit-content !important;
  .ant-modal-content {
    padding: 0;

    .ant-modal-close {
      // !important убрать не получится тк стили переписаны глобально
      width: 40px !important;
      height: 40px !important;
      background-color: #141414;
      color: white; 
      border: 1px solid #424242;
      border-radius: 8px;
      margin-right: -15px;
      margin-top: -20px;
    }

    .ant-modal-close:hover {
      border: 1px solid white;
    }
  }
`;
