import { BadgeStyled, WrapperBadgeStyled } from './assets/styles';
import { FunctionComponent, memo } from 'react';

type TChatListItemMessageCounter = {
  count: number;
};

const ChatListItemMessageCounter: FunctionComponent<TChatListItemMessageCounter> = memo(({
  count
}) => {
  return (
    <WrapperBadgeStyled className='chat-message-counter'>
      <BadgeStyled showZero={false} count={count} />
    </WrapperBadgeStyled>
  )
});

export default ChatListItemMessageCounter;
