import React from 'react';
import { INotification } from '../../model/types';
import { useTranslation } from 'react-i18next';
import {
  EmptyNotificationsBlockStyled,
  EmptyNotificationsTextStyled,
  LinkInEmptyStyled,
  NotificationEmptyStyled, WrapSkeletonStyled,
} from './NotificationAsideList.style';
import { EmptyNotificationsIcon } from '../../../../assets/img/icons/icons';
import { useNotificationAsideState } from '../../model/useNotificationAsideState';
import NotificationAsideListItem from './NotificationAsideListItem/NotificationAsideListItem';

export const NotificationAsideList = () => {

  const { t } = useTranslation();

  const notifications = useNotificationAsideState(state => state.notifications)
  const isNotificationsLoading = useNotificationAsideState(state => state.isNotificationsLoading)
  const totalNotificationsCount = useNotificationAsideState(state => state.totalNotificationsCount)

  if (!isNotificationsLoading && notifications.length && totalNotificationsCount !== 0) {
    return (
      <>
        {notifications.map((el: INotification) => {
          return (
            <NotificationAsideListItem key={el.id} notification={el} />
          )
        })}
      </>
    )
  }

  return (
    <>
      {isNotificationsLoading &&  Array.from({ length: 4 }).map((_, i) => (
        <WrapSkeletonStyled key={i} active />
      ))}
      {!isNotificationsLoading && <NotificationEmptyStyled>
        <EmptyNotificationsIcon />
        <EmptyNotificationsBlockStyled>
          <EmptyNotificationsTextStyled>{t('Нет новых уведомлений')}</EmptyNotificationsTextStyled>
          <LinkInEmptyStyled to={"/notifications"}>{t('Смотреть все уведомления')}</LinkInEmptyStyled>
        </EmptyNotificationsBlockStyled>
      </NotificationEmptyStyled>}
    </>
  );
};

