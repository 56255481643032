import styled from 'styled-components';
import { Badge } from 'antd';

export const BadgeStyled = styled(Badge)`
  .ant-badge-status-cyan {
    background: #0ec6ad;
  }
`;

export const WrapperBadgeStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;

  .ant-badge .ant-badge-count {
    padding: 2px 8px;
    height: 24px;
    min-width: 24px;
    background-color: #08979c;
    border-radius: 16px;
    font-weight: 500;
  }
`;
