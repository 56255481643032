import { FunctionComponent, memo } from 'react';

import Gavel from 'assets/img/icons/gavel.svg';
import { Tooltip } from 'antd';
import { TTabs } from 'pages/chat-list/state/useChatListState';
import { TChatListTranslations } from '../../hooks/useChatListItemTranslations';

import {
  ChatListOperationTitle,
  GavelStyled,
  TitleText,
} from './ChatListItemTypePair.style';

type TChatListItemTypePair = {
  isSellingCrypto?: boolean;
  pair: string;
  isRefereeInChat: boolean;
  currentTabKey: TTabs;
  translations: TChatListTranslations
};

const ChatListItemTypePair: FunctionComponent<TChatListItemTypePair> = memo(({
  isSellingCrypto,
  pair,
  isRefereeInChat,
  currentTabKey,
  translations
}) => {
  const isSell = currentTabKey === 'corporate' ? !isSellingCrypto : isSellingCrypto
  const title = `${isSell ? translations.sell : translations.buy} ${pair}`

  return (
    <ChatListOperationTitle>
      <TitleText>
        {title}
      </TitleText>
      {isRefereeInChat && (
        <div>
          <Tooltip title={translations.referreeTooltip} trigger={'hover'}>
            <GavelStyled src={Gavel} alt={'Gavel'} />
          </Tooltip>
        </div>
      )}
    </ChatListOperationTitle>
  );
});

export default ChatListItemTypePair;
