import styled from 'styled-components';

export const MapWrapper = styled.div`
  position: relative;
`;

export const InputWrapper = styled.div`
  position: absolute;
  width: 700px;
  top: 24px;
  left: calc(50% - 240px);
  z-index: 999;
  .map-input {
    // Не получится убрать !important тк стили переписаны глобально 
    background-color: #ffffff !important;
    color: #000000e0;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    width: 500px;
    border: 1px solid #00000026;

  }
`;
