import React, { useEffect, useRef, useState } from 'react';
import { ChatMessage } from '../message';
import { TimeMark } from '../time-mark';
import {
  IChatNotificationMessage,
  IChatOrderUpdatedMessage,
  IChatTimemarkMessage,
  IUloadChatMessage,
} from '../../../interfaces/entity';
import styled from 'styled-components';
import { useCookiesCustom } from '../../../../../hooks/useCookieCustom';
import { useMessagesState } from '../../../states/useMessagesState';

type TChatListMessages = {
  messages: any[];
};

const ChatListMessages = ({ messages }: TChatListMessages) => {
  const { cookie } = useCookiesCustom();

  const urlParams = new URLSearchParams(window.location.search);
  const offerDealId = urlParams.get('offer_deal_id') as string;

  const messagesFromState = useMessagesState((state) => state.messages);

  const lastMessageRef = useRef<any>(null);

  const chatInfo = useMessagesState(
    (state: { chatInfo: any }) => state.chatInfo
  );

  const [messagesWithTimemark, setMessagesWithTimemark] = useState<
    (
      | IUloadChatMessage
      | IChatNotificationMessage
      | IChatOrderUpdatedMessage
      | IChatTimemarkMessage
    )[]
  >([]);

  const integrateTimemarkInMessages = (
    messages: (
      | IUloadChatMessage
      | IChatNotificationMessage
      | IChatOrderUpdatedMessage
    )[]
  ) => {
    const newArray: any[] = [];
    for (let i = messages.length - 1; i >= 0; i--) {

      if (
        messages[i]?.chat?.entity_id?.toString() !== offerDealId?.toString() && offerDealId
      ) {
        break;
      }
      newArray.push(messages[i]);
      if (
        i !== 0 &&
        new Date(messages[i].created_at).getDate() !==
          new Date(messages[i - 1].created_at).getDate()
      ) {
        newArray.push({
          type: 'timemark',
          date: new Date(messages[i].created_at),
        });
      }
    }
    setMessagesWithTimemark(newArray.reverse());
  };

  const getMessageComponent = (messageItem: any) => {
    if (messageItem.type === 'timemark') {
      return (
        <TimemarkWrapper key={messageItem?.id}>
          <TimeMark bigSize date={messageItem.date || new Date()} />
        </TimemarkWrapper>
      );
    }
    if (!messageItem.is_system) {
      return (
        <ChatMessage
          key={messageItem.id}
          date={new Date(messageItem.created_at)}
          float={cookie['uid'] === messageItem?.sender_id ? 'right' : 'left'}
          messageData={messageItem}
          attachment={messageItem.attachment}
          message={messageItem.text as string}
          participant={messageItem?.participant}
          // TODO: Добавить Nickname если есть
          senderNickName={
            // buyerId === messageItem?.sender_id
            //     ? buyerInfo?.nickname
            //     : sellerInfo?.nickname
            ''
          }
        />
      );
    }
  };

  useEffect(() => {
    const lastMessage = lastMessageRef.current;
    if (lastMessage) {
      setTimeout(() => {
        lastMessage.parentElement.scrollTo({
          top: lastMessage.offsetTop + lastMessage.offsetHeight,
          behavior: 'smooth',
        });
      }, 700);
    }
  }, [messagesWithTimemark]);

  useEffect(() => {
    integrateTimemarkInMessages(messages);
  }, [messages, messagesFromState.length]);

  const renderMessages = () => {
    if (!!messagesWithTimemark?.length) {
      return (
        <>
          {!!messagesWithTimemark?.length &&
            messagesWithTimemark.map((messageItem) =>
              getMessageComponent(messageItem)
            )}
        </>
      );
    } else {
      return <EmptyMessages>No messages</EmptyMessages>;
    }
  };

  return (
    <MessagesContainer>
      {renderMessages()}
      <div ref={lastMessageRef} />
    </MessagesContainer>
  );
};

const EmptyMessages = styled.div`
  background: #474755;
  padding: 4px 12px 4px 12px;
  border-radius: 4px;
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.52px;
  color: #fbfbff;
  display: flex;
  justify-self: center;
  align-self: center;
  height: 100%;
`;

const MessagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 6px rgb(255, 255, 255);
    background: #f3f3f8;
    border-radius: 4px;
  }
`;

const TimemarkWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px 0;
`;

export { ChatListMessages };
