import { FunctionComponent } from "react"
import Icon, { UserOutlined } from "@ant-design/icons"

import { ChatListItemParticipant, ChatListParticipantName } from "./ChatListItemParticipant.style"
import { CorporateIcon } from "assets/img/icons/icons"
import { RatingTooltip } from "shared/ui/tooltips/RatingTooltip"
import { IExchangePointMetrics } from "interfaces"

interface ICompanyProps {
  companyName?: string
  metrics?: IExchangePointMetrics
}

export const ChatListItemCompany: FunctionComponent<ICompanyProps> = (props) => {
  const { metrics, companyName } = props

  return (
    <ChatListItemParticipant>
      <Icon className='chat-list-icon' component={CorporateIcon} />
      <RatingTooltip metrics={metrics}>
        <ChatListParticipantName>
          {companyName}
        </ChatListParticipantName>
      </RatingTooltip>
    </ChatListItemParticipant>
  )
}

interface IClientProps {
  clientNickname?: string
}

export const ChatListItemClient: FunctionComponent<IClientProps> = ({ clientNickname }) => {
  return (
    <ChatListItemParticipant>
      <UserOutlined className='chat-list-icon' />
      <ChatListParticipantName>
        {clientNickname}
      </ChatListParticipantName>
    </ChatListItemParticipant>
  )
}