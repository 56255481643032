import styled, { css } from 'styled-components';
import { Typography } from 'antd';

export const UserInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const UserInfoContainerStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;

export const UserInfoNicknameStatusStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const UserInfoContainerTitleStyled = styled(Typography.Text)<{
  $isAdmin?: boolean;
}>`
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 16.94px;
  text-align: left;
  color: #fbfbff;
  ${({ $isAdmin }) =>
    !$isAdmin &&
    css`
      cursor: pointer;
      transition: 0.3s;
      &:hover {
        color: #4096ff;
      }
    `}}
`;

export const UserInfoContainerDescStyled = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  color: #767687;
  transition: ease-in-out 0.5s;
  cursor: pointer;

  //&:hover {
  //    color: #4096FF;
  //}
`;
