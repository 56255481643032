import { axios } from '../../../shared/exios';
import { FINMS_URL_API } from '../../../packages/keycloak-client/constants';
import { TNotificationsFilters } from '../model/types';

export const getNotificationAside = async (filters?: TNotificationsFilters) => {
  try {
    const response = await axios.post(
      `${FINMS_URL_API}/server/api/notifications/search`,
      {
        limit: 999,
        filters: filters || [{
          field: 'is_read',
          operator: '=',
          value: false,
        }],
        sort: [{ field: 'created_at', direction: 'desc' }],
        includes: [
          {
            relation: 'userAccount',
          },
        ],
      }
    )
    return response.data
  } catch (e) {
    return null;
  }
}

export const getNotificationAsideCount = async () => {
  try {
    const response = await axios.get(
      `${FINMS_URL_API}/server/api/notifications/get-count`
    );
    return response.data
  } catch (e) {
    return null;
  }
}


export const readAllNotifications = async () => {
  try {
    return await axios.post(
      `${FINMS_URL_API}/server/api/notifications/readAllNotifications`
    );
  } catch (e) {
    return null;
  }
}

export const readCurrentNotification = async (id: number) => {
  try {
    return await axios.put(
      `${FINMS_URL_API}/server/api/notifications/${id}`,
      {
        is_read: true,
      }
    );
  } catch (e) {
    return null;
  }
}
