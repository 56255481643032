import styled from 'styled-components';
import { DownOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { MEDIA_BREAKPOINT } from 'shared/constants/media-breakpoints';

export const DownOutlinedStyled = styled(DownOutlined)<{ rotate?: boolean, color?: string }>`
  font-size: 16px;

  svg {
    color: ${(({color}) => color ?? 'rgba(255,255,255,0.88)')};
    transition: 0.4s ease transform;
    transform: ${(props) => (props.rotate ? 'rotate(180deg)' : 'rotate(0deg)')};
  }
`;

export const AllFiltersButton = styled(Button)`
  padding: 0;
  height: auto;
  border: none;
  font-size: 16px;
  line-height: 1;
  color: var(--deals-white-shaded);

  &.ant-btn {
    background-color: transparent;
  }
`;

export const GridRowStyled = styled.div`
  ${AllFiltersButton} {
    display: none;
  }

  @media ${MEDIA_BREAKPOINT.MD} {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 16px;
    align-items: end;

    ${AllFiltersButton} {
      display: block;
    }
  }
`;
