import { devtools } from 'zustand/middleware';
import create from 'zustand';
import { produce } from 'immer';
import { axios } from '../../../shared/exios';
import {
  EXCHANGE_MAP_API_URL,
  FINMS_URL_API,
} from '../../../packages/keycloak-client/constants';
import { IChat, IFilter, IFilterSimple } from '../../../interfaces';
import { useMessagesState } from '../../../components/ChatModule/states/useMessagesState';
import { calcAuthToken } from '../../../components/ChatModule/helpers/calcAuthToken';

import { IFiltersData } from '../model/types';

export type TTabs = 'personal' | 'corporate';

type TChatListState = {
  currentChat: null | any;
  phexChatsTotalPages: number;
  phexChatsTotalItems: number;
  chatListTotalPages: number;
  chatListTotalItems: number;
  isOpenChat: boolean;
  openChatId: null | number;
  phexChatsList: IChat[];
  partnersChatsList: IChat[];
  setIsOpenChat: (value: boolean) => void;
  setOpenChatId: (value: number | null) => void;
  getPartnersChatsList: () => Promise<any>;
  getPhexChatsList: () => Promise<any>;
  setChatListTotalPages: (value: number) => void;
  resetFields: () => void;
  isLoadingSkeleton: boolean;
  isLoadingLastChat: boolean;
  setIsLoadingSkeleton: (value: boolean) => void;
  setIsLoadingLastChat: (value: boolean) => void;

  currentPage: number;
  setCurrentPage: (value: number) => void;

  deletePhexChatsList: () => void;
  deletePartnersChatsList: () => void;
  typeTabs: TTabs;
  setTypeTabs: (value: string) => void;
  setCurrentChat: () => void;

  clients: IFilterSimple[];
  statusFilters: IFilterSimple[];
  filterPayload: IFilter[];
  typeDealFilters: IFilterSimple[];
  points: IFilterSimple[];
  currencies: IFilterSimple[];
  temporaryFilter: boolean;
  setFilterPayload: () => void;
  setTemporaryFilter: () => void;
  setCurrentFilter: (
    value: number[] | string | number | string[],
    type: string
  ) => void;
  setOptionsFilters: (options: IFiltersData[]) => void;
  resetFilters: () => void;
  deleteFilter: (
    type: string,
    value: number[] | string | number | string[]
  ) => void;
};

export const useChatListState = create<TChatListState>()(
  devtools(
    (set, get) => ({
      phexChatsTotalPages: 0,
      phexChatsTotalItems: 0,
      chatListTotalPages: 0,
      chatListTotalItems: 0,
      isOpenChat: false,
      openChatId: null,
      partnersChatsList: [],
      phexChatsList: [],
      isLoadingSkeleton: true,
      isLoadingLastChat: false,
      currentPage: 1,
      typeTabs: 'personal',
      currentChat: null,

      clients: [],
      filterPayload: [],
      temporaryFilter: false,
      points: [],
      currencies: [],
      typeDealFilters: [
        {
          name: 'purchase',
          label: 'Покупка',
          checked: false,
        },
        {
          name: 'sell',
          label: 'Продажа',
          checked: false,
        },
      ],
      statusFilters: [
        {
          name: 'accepted',
          label: 'Принята',
          checked: false,
        },
        {
          name: 'cancelled',
          label: 'Отменена',
          checked: false,
        },
        {
          name: 'warranty_collateral',
          label: 'Обеспечение гаранта',
          checked: false,
        },
        {
          name: 'waiting_for_fiat_payment',
          label: 'Ожидание оплаты фиатом',
          checked: false,
        },
        {
          name: 'ready',
          label: 'Готова к обмену',
          checked: false,
        },
        {
          name: 'expired',
          label: 'Просрочена',
          checked: false,
        },
        {
          name: 'on_argument',
          label: 'Открыт спор',
          checked: false,
        },
        {
          name: 'completed',
          label: 'Выполнена',
          checked: false,
        },
      ],
      setCurrentChat: () => {
        set(
          produce((draft) => {
            const typeTabs = draft.typeTabs;
            useChatListState?.getState().setIsOpenChat(false);
            useMessagesState?.getState().setIsFetchingMessages(true);
            useMessagesState.getState().clearMessages();
            if (typeTabs === 'personal') {
              const currentChat = draft.partnersChatsList.find(
                (chat: IChat) => chat?.id === draft.openChatId
              );
              draft.currentChat = currentChat;
              useMessagesState
                .getState()
                .searchMessages({
                  entity_type: currentChat?.entity_type,
                  entity_id: String(
                    currentChat?.entity_type === 'offer'
                      ? currentChat?.offer_id
                      : currentChat?.offer_deal?.id
                  ),
                  client_uid: currentChat?.client_uid,
                  auth_token: calcAuthToken({ chatType: 'list', typeTabs }),
                })
                .then();
            } else {
              const currentChat = draft.phexChatsList.find(
                (chat: IChat) => chat?.id === draft.openChatId
              );
              draft.currentChat = currentChat;
              useMessagesState
                .getState()
                .searchMessages({
                  entity_type: currentChat?.entity_type,
                  entity_id: String(
                    currentChat?.entity_type === 'offer'
                      ? currentChat?.offer_id
                      : currentChat?.offer_deal?.id
                  ),
                  client_uid: currentChat?.client_uid,
                  auth_token: calcAuthToken({ chatType: 'list', typeTabs }),
                })
                .then();
            }
          })
        );
      },

      setTypeTabs: (value: string) => {
        set(
          produce((draft) => {
            draft.typeTabs = value;
          })
        );
        get().resetFilters();
      },

      deletePartnersChatsList: () => {
        set(
          produce((draft) => {
            draft.partnersChatsList = [];
          })
        );
      },

      deletePhexChatsList: () => {
        set(
          produce((draft) => {
            draft.phexChatsList = [];
          })
        );
      },

      setCurrentPage: (value) => {
        set(
          produce((draft) => {
            draft.currentPage = value;
          })
        );
      },

      resetFields: () => {
        set(
          produce((draft) => {
            draft.phexChatsTotalPages = 0;
            draft.phexChatsTotalItems = 0;
            draft.chatListTotalPages = 0;
            draft.chatListTotalItems = 0;
            draft.currentPage = 1;
            draft.isOpenChat = false;
            draft.openChatId = null;
            draft.partnersChatsList = [];
            draft.phexChatsList = [];
            draft.isLoadingSkeleton = true;
            draft.IsLoadingLastChat = false;
          })
        );
      },
      setIsLoadingLastChat: (value) => {
        set(
          produce((draft) => {
            draft.isLoadingLastChat = value;
          })
        );
      },
      setIsLoadingSkeleton: (value) => {
        set(
          produce((draft) => {
            draft.isLoadingSkeleton = value;
          })
        );
      },
      setChatListTotalPages: (value: number) => {
        set(
          produce((draft) => {
            draft.chatListTotalPages = value;
          })
        );
      },
      setOpenChatId: (value: number | null) => {
        set(
          produce((draft) => {
            draft.openChatId = value;
          }),
          false,
          {
            type: 'useChatListState => setStep',
          }
        );
      },
      setIsOpenChat: (value: boolean) => {
        set(
          produce((draft) => {
            draft.isOpenChat = value;
          }),
          false,
          {
            type: 'useChatListState => setStep',
          }
        );
      },
      getPartnersChatsList: async () => {
        try {
          const res = await axios.post(
            `${EXCHANGE_MAP_API_URL}/partner/api/chats/search`,
            {
              page: get().currentPage,
              limit: 7,
              includes: [
                {
                  relation: 'offer',
                },
                {
                  relation: 'offer_deal',
                },
                { relation: 'offer.exchange_point.company' },
                { relation: 'offer.exchange_point.metrics' },
                { relation: 'offer.cash_payment_system_currency' },
                { relation: 'offer.crypto_payment_system_currency' },
              ],
              filters: [...get().filterPayload],
            }
          );
          const arr = res?.data?.data;

          set(
            produce((draft) => {
              const uniqueArray = Array.from(
                new Map(
                  [...draft.partnersChatsList, ...arr].map((item) => [
                    item.id,
                    item,
                  ])
                ).values()
              );

              draft.partnersChatsList = uniqueArray;
              draft.chatListTotalPages = res?.data?.meta?.last_page;
              draft.chatListTotalItems = res?.data?.meta?.total;
            })
          );
        } catch (e) {
          console.error('getPartnersChatsList error:', e);
        } finally {
          set(
            produce((draft) => {
              draft.isLoadingSkeleton = false;
              draft.isLoadingLastChat = false;
            })
          );
        }
      },
      getPhexChatsList: async () => {
        try {
          const res = await axios.post(
            `${FINMS_URL_API}/server/api/chats/search`,
            {
              page: get().currentPage,
              includes: [
                {
                  relation: 'offer',
                },
                {
                  relation: 'offer_deal.client',
                },
                { relation: 'offer.exchange_point.company' },
                { relation: 'offer.exchange_point.metrics' },
                { relation: 'offer.cash_payment_system_currency' },
                { relation: 'offer.crypto_payment_system_currency' },
              ],
              filters: [...get().filterPayload],
            }
          );
          const arr = res?.data?.data;
          set(
            produce((draft) => {
              const uniqueArray = Array.from(
                new Map(
                  [...draft.phexChatsList, ...arr].map((item) => [
                    item.id,
                    item,
                  ])
                ).values()
              );

              draft.phexChatsList = uniqueArray;
              draft.phexChatsTotalPages = res?.data?.meta?.last_page;
              draft.phexChatsTotalItems = res?.data?.meta?.total;
            })
          );
        } catch (e) {
          console.error('getPhexChatsList error:', e);
        } finally {
          set(
            produce((draft) => {
              draft.isLoadingSkeleton = false;
              draft.isLoadingLastChat = false;
            })
          );
        }
      },
      setPhexChatsTotalPages: (value: number) => {
        set(
          produce((draft) => {
            draft.phexChatsTotalPages = value;
          })
        );
      },
      setFilterPayload: () => {
        const newFilterPayload: IFilter[] = [];
        set(
          produce((draft) => {
            draft.curPage = 1;
            draft.deals = [];

            const isBuying = draft.typeDealFilters.find(
              (cur: IFilterSimple) => cur.checked
            );

            if (isBuying) {
              const type = draft.typeTabs === 'personal' ? 'sell' : 'purchase';
              newFilterPayload.push({
                field: 'offer.is_buying_crypto',
                operator: '=',
                value: isBuying.name === type,
              });
            }

            const points = draft.points.find(
              (cur: IFilterSimple) => cur.checked
            );
            if (points) {
              newFilterPayload.push({
                field: 'offer.exchange_point.id',
                operator: '=',
                value: points.name,
              });
            }

            const status = draft.statusFilters.find(
              (cur: IFilterSimple) => cur.checked
            );
            if (status) {
              newFilterPayload.push({
                field: 'offer_deal.status',
                operator: '=',
                value: status.name,
              });
            }

            if (status) {
              draft.temporaryFilter = false;
            }

            if (draft.temporaryFilter) {
              newFilterPayload.push({
                field: 'offer_deal_id',
                operator: '=',
                value: null,
              });
            }

            const currencies = draft.currencies.find(
              (cur: IFilterSimple) => cur.checked
            );
            if (currencies) {
              newFilterPayload.push({
                field: 'offer.cash_currency_code',
                operator: '=',
                value: currencies.name,
              });
            }

            const client = draft.clients.find(
              (cur: IFilterSimple) => cur.checked
            );
            if (client) {
              newFilterPayload.push({
                field: 'offer_deal.client.name',
                operator: '=',
                value: client.name,
              });
            }

            draft.filterPayload = newFilterPayload;
          }),
          false,
          {
            type: 'useDealsState => setFilterPayload',
          }
        );
      },
      setTemporaryFilter: () => {
        set(
          produce((draft) => {
            draft.temporaryFilter = !draft.temporaryFilter;
            if (draft.temporaryFilter) {
              draft.statusFilters = draft.statusFilters.map(
                (el: IFilterSimple) => ({
                  ...el,
                  checked: false,
                })
              );
            }
          })
        );
        get().setFilterPayload();
      },
      setCurrentFilter: (value, type) => {
        set(
          produce((draft) => {
            draft.curPage = 1;

            const isArray = Array.isArray(value);
            const isAll = isArray && (value as unknown[]).includes('all');

            draft[type] = draft[type].map((elem: IFilterSimple) => {
              if (isArray) {
                if (!value.length || isAll) {
                  elem.checked = false;
                  return elem;
                }

                elem.checked = (value as unknown[]).includes(elem.name);
              } else {
                elem.checked = elem.name === value;
              }

              return elem;
            });
          }),
          false,
          {
            type: 'useDealsState => setFilter',
          }
        );
        get().setFilterPayload();
      },
      setOptionsFilters: (options) => {
        options.forEach((el) => {
          set(
            produce((draft) => {
              if (el.type === 'fiat') {
                draft.currencies = el.data.map((currency) => ({
                  name: currency.currency_key,
                  label: currency.currency_key,
                  checked: false,
                }));
              }

              if (el.type === 'point') {
                draft.points = el.data.map((point) => ({
                  name: String(point.id),
                  label: point.name,
                  checked: false,
                }));
              }

              if (el.type === 'client') {
                draft.clients = el.data.map((client) => ({
                  name: client.name,
                  label: client.name,
                  checked: false,
                }));
              }
            }),
            false,
            {
              type: 'useDealsState => setOptionsFilters',
            }
          );
        });
      },
      deleteFilter: (type, value) => {
        set(
          produce((draft) => {
            if (type === 'createDateDeal' || type === 'completionDateDeal') {
              draft[type] = undefined;
              return;
            }

            draft[type] = draft[type].map((el: IFilterSimple) => {
              if (el.name === value) {
                el.checked = false;
              }
              return el;
            });
          }),
          false,
          {
            type: 'useDealsState => deleteFilter',
          }
        );

        get().setFilterPayload();
      },
      resetFilters: () => {
        set(
          produce((draft) => {
            draft.typeDealFilters = draft.typeDealFilters.map(
              (el: IFilterSimple) => ({
                ...el,
                checked: false,
              })
            );
            draft.points = draft.points.map((el: IFilterSimple) => ({
              ...el,
              checked: false,
            }));
            draft.currencies = draft.currencies.map((el: IFilterSimple) => ({
              ...el,
              checked: false,
            }));
            draft.statusFilters = draft.statusFilters.map(
              (el: IFilterSimple) => ({
                ...el,
                checked: false,
              })
            );
            draft.clients = draft.clients.map((el: IFilterSimple) => ({
              ...el,
              checked: false,
            }));
            draft.temporaryFilter = false;
            draft.phexChatsTotalPages = 0;
            draft.phexChatsTotalItems = 0;
            draft.chatListTotalPages = 0;
            draft.chatListTotalItems = 0;
          }),
          false,
          {
            type: 'useDealsState => resetFields',
          }
        );

        get().setFilterPayload();
      },
    }),
    {
      anonymousActionType: 'useChatListState action',
      name: 'useChatListState',
    }
  )
);
