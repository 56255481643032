import styled from 'styled-components';
import { Tag } from 'antd';
import { Link } from 'react-router-dom';
import { ACCOUNT_URL, FINMS_URL } from '../../../packages/keycloak-client/constants';
import { useAuthState } from '../../../state/useAuthState';

function JsxCreator(
  items: { type: string; text: string }[],
  link: string,
  topic_key: string,
  notificationType: string
) {

  const userFinmsApi = useAuthState((state) => state.userFinmsApi);

  return (
    items?.length &&
    items?.map((item) => {
      const getFullLink = (notificationType: string) => {
        if (notificationType === 'corporate' && userFinmsApi) {
          return FINMS_URL + link
        }
        if (notificationType === 'my_deals') {
          return ACCOUNT_URL + link
        }
        return link
      }

      if (item.type === 'link') {
        if (items[items.length - 1].type === 'exPointId') {
          return (
            <Link
              target={"_blank"}
              to={getFullLink(notificationType)}
            >
              {item.text}
            </Link>
          );
        }

        if (topic_key === 'exchange') {
          return <a target={"_blank"} href={notificationType === 'corporate' ? FINMS_URL + link : link} rel="noreferrer">{item.text}</a>;
        }

        return <Link target={"_blank"} to={notificationType === 'corporate' ? FINMS_URL + link : link}>{item.text}</Link>;
      }

      if (item.type === 'corporate' || item.type === 'accountTypes') {
        return <DefaultTag>{item.text}</DefaultTag>;
      }
      if (
        item.type === 'opChainStatusWaiting' ||
        item.type === 'operationStatusWaiting'
      ) {
        return <OrangeTag>{item.text}</OrangeTag>;
      }
      if (
        item.type === 'opChainStatusClosed' ||
        item.type === 'operationStatusClosed'
      ) {
        return <CyanTag>{item.text}</CyanTag>;
      }
      if (
        item.type === 'opChainStatusCanceled' ||
        item.type === 'operationStatusCanceled'
      ) {
        return <RedTag>{item.text}</RedTag>;
      }
      return <></>;
    })
  );
}

export const useNotificationParser = (
  original: string,
  values: { type: string; text: string }[] | string,
  link: string,
  topic_key: string,
  notificationType: string
) => {
  let valuesData = values;
  if (typeof values === 'string') {
    try {
      if (values.trim().startsWith('[') && values.trim().endsWith(']')) {
        valuesData = JSON.parse(values);
      } else {
        valuesData = [];
      }
    } catch (error) {
      valuesData = [];
    }
  }

  if (!Array.isArray(valuesData)) {
    return { texts: [''], elems: [] };
  }

  const textArr = original.split('%s');
  const arr = JsxCreator(valuesData, link, topic_key, notificationType);

  return { texts: textArr, elems: arr };
};

const RedTag = styled(Tag)`
  border-color: #58181c;
  background-color: #2a1215;
  color: #d32029;
`;

const OrangeTag = styled(Tag)`
  border-color: #593815;
  background-color: #2b1d11;
  color: #d87a16;
`;

const CyanTag = styled(Tag)`
  border-color: #144848;
  background-color: #112123;
  color: #13a8a8;
`;

const DefaultTag = styled(Tag)`
  border-color: #474755;
  background-color: #2b2b36;
  color: #ffffffd9;
`;
