import { ReactComponent as LocateIcon } from 'assets/img/icons/map/near_me.svg';
import { RefObject } from 'react';
import L from 'leaflet';
import {
  ButtonStyled,
  ControlsStyled,
  MinusOutlinedStyled,
  PlusOutlinedStyled,
} from './MapControls.styles';

interface MapControlsProps {
  mapRef: RefObject<L.Map> | null;
}

export const MapControls = ({ mapRef }: MapControlsProps) => {
  const plusButtonClickHandler = () => {
    const element = document.querySelector(
      '.leaflet-control-zoom-in'
    ) as HTMLElement;
    element?.click();
  };

  const minusButtonClickHandler = () => {
    const element = document.querySelector(
      '.leaflet-control-zoom-out'
    ) as HTMLElement;
    element?.click();
  };

  const locateButtonClickHandler = () => {
    mapRef?.current?.locate({ setView: true });
  };
  return (
    <ControlsStyled>
      <ButtonStyled onClick={() => plusButtonClickHandler()}>
        <PlusOutlinedStyled />
      </ButtonStyled>
      <ButtonStyled onClick={() => minusButtonClickHandler()}>
        <MinusOutlinedStyled />
      </ButtonStyled>
      <ButtonStyled onClick={() => locateButtonClickHandler()}>
        <LocateIcon />
      </ButtonStyled>
    </ControlsStyled>
  );
};
