import { styled } from 'styled-components';
import { BasicTabs } from 'shared/ui/basic-tabs';
import { Button } from 'antd';

export const TabLabelStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const ChatListFiltersStyled = styled.div`
  margin-bottom: 20px;
`;

export const TabsStyled = styled(BasicTabs)`
  width: 100%;

  &.ant-tabs {
    .ant-tabs-tab {
      padding-left: 0;
      padding-right: 0;
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
      color: rgba(255, 255, 255, 0.8);

      &.ant-tabs-tab-active {
        .ant-tabs-tab-btn {
          color: #1677ff;
        }
      }
    }
  }

  .ant-tabs-ink-bar {
    background-color: #1677ff;
  }

  .ant-badge-status-cyan {
    background: #0ec6ad;
  }
`;

export const TabsContainer = styled.div`
  display: flex;
  justify-content: end;
  gap: 12px;
  margin-bottom: 24px;
`;

export const AllFiltersButton = styled(Button)`
  padding: 0;
  height: auto;
  border: none;
  font-size: 16px;
  line-height: 1;
  color: var(--deals-white-shaded);
  align-items: end;

  &.ant-btn {
    background-color: transparent;
  }
`;

export const FilterWrapper = styled.div<{ $isCorporate: boolean }>`
  & div {
    @media (min-width: 1280px) {
      grid-template-columns: repeat(
        ${({ $isCorporate }) => ($isCorporate ? 5 : 4)},
        minmax(0, 1fr)
      );
    }
  }
`;
