import s from './index.module.css';
import {IUploadAttachment} from '../../../../interfaces/entity';
import PdfFileBlock from '../../../form/filePreview/components/pdfFileBlock/index';
import {TimeMark} from '../../time-mark';
import {Download} from '../../../../assets/DownloadIcon';
import {LinkStyled, ModalStyled} from "./assets/styles";

interface IModalImage {
    attachment: IUploadAttachment;
    fileDownloadLink: string;
    onClose: () => void;
    open: boolean;
    date: Date;
    senderNickname: string | undefined;
}

const MessageModalFile = ({
                              date,
                              fileDownloadLink,
                              open,
                              onClose,
                              attachment,
                              senderNickname,
                          }: IModalImage) => {
    return (
        <ModalStyled open={open} onCancel={onClose}>
            <div className={s.imgWrapper}>
                {attachment.file_extension === 'pdf' ? (
                    <PdfFileBlock name={attachment.file_name} size={attachment.id} handleDeleteFile={() => {
                    }}/>
                ) : (
                    <img src={fileDownloadLink} alt="attachment"/>
                )}
            </div>
            <div className={s.img__date}>
                <div>{senderNickname}</div>
                <TimeMark date={date}/>
            </div>
            <LinkStyled
                target={'_blank'}
                href={fileDownloadLink}
                className={s.img__download}
                download={attachment.file_name}
            >
                <Download/>
                <div>Скачать</div>
            </LinkStyled>
        </ModalStyled>
    );
};

export {MessageModalFile};
