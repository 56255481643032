import { TFilter } from "./types"

export const prepareFilterProps = (
  filters: TFilter[], 
  deleteFilter: (type: string, value: number[] | string | number | string[]) => void,
  t: (s: string) => string
) => {
  const set = filters.reduce<Set<{
    key: string
    label: string
    onClose: (e: unknown) => void
  }>>((set, filter) => {
    // if select
    if (Array.isArray(filter.options)) {
      filter.options.forEach(option => {
        if (!option.checked) {
          return
        }

        const label = filter.type === 'statusFilters'
          ? t(`${option.label}`)
          : option.label

        set.add({
          key: `tag:${filter.type}:${option.name}`,
          label,
          onClose: () => deleteFilter(filter.type, option.name)
        })
      })

    // if date range picker
    } else if (filter.valueDate) {
      const startDate = new Date(filter?.valueDate[0])
      const endDate = new Date(filter?.valueDate[1])

      const label = `${startDate.getDate()}/${startDate.getMonth()}/${startDate.getFullYear()} - ` +
        `${endDate.getDate()}/${endDate.getMonth()}/${endDate.getFullYear()}`

      set.add({
        key: `tag:${filter.type}`,
        label,
        onClose: () => deleteFilter(filter.type, [])
      })
    }

    return set
  }, new Set())

  return Array.from(set)
}