import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const ChatAsideContainerStyled = styled.div`
  position: absolute;
  top: 12px;
  //right: -180%;
  transform: translateX(-49%);
  background: #14141f;
  padding: 0 1px 1px 1px;
  height: calc(100vh - 81px);
  width: 480px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  border-width: 1px 0 0 1px;
  border-style: solid;
  border-color: rgba(65, 61, 80, 0.5);\

`;

export const ChatAsideHeaderStyled = styled.div`
  background: #14141f;
  padding: 32px 16px 0;
  gap: 32px;
  display: flex;
  justify-content: space-between;
  min-height: 45px;
  height: 45px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
`;

export const ChatAsideTitleStyled = styled.div`
  font-weight: 700;
  font-size: 24px;
  color: rgba(251, 251, 255, 1);
`;

export const ChatAsideFooterStyled = styled.div`
  position: sticky;
  left: 0;
  bottom: 0;
  background: inherit;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ChatAsideLinkStyled = styled(Link)`
  font-size: 16px;
  color: rgba(255, 255, 255, 0.8);
  cursor: pointer;
  &:hover {
    color: rgba(22, 119, 255, 1);
  }
`;
