import * as React from 'react';
import { ConfigProvider, Space, Spin } from 'antd';
import styled from 'styled-components';

export function FullScreenLoader() {
  return (
    <ConfigProvider
      theme={{
        components: {
          Spin: {
            colorPrimary: '#742EFD',
          },
        },
      }}
    >
      <FullScreenLoaderWrapper>
        <Spin />
      </FullScreenLoaderWrapper>
    </ConfigProvider>
  );
}

const FullScreenLoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
`;

export const AdaptiveFullScreenLoader = () => {
  return (
    <ConfigProvider
      theme={{
        components: {
          Spin: {
            colorPrimary: '#742EFD',
          },
        },
      }}
    >
      <AdaptiveFullScreenLoaderWrapper>
        <Spin />
      </AdaptiveFullScreenLoaderWrapper>
    </ConfigProvider>
  );
};

const AdaptiveFullScreenLoaderWrapper = styled(Space)`
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;
