import {Button, Card, Form, Input, Modal, notification} from 'antd';
import React, {useState} from 'react';
import Icon from '@ant-design/icons';
import {CheckMarkIcon, CloseIcon, CrossMarkIcon} from '../../../../assets/img/icons/icons';
import styled from 'styled-components';
import {t} from 'i18next';
import {EDENEX_ACCOUNT_URL} from '../../../../packages/keycloak-client/constants';
import {useCredentialsState} from '../../../../state/useCredentialsState';
import {IconNotificationSuccess} from '../../../../components/ui/icons';
import {axios} from '../../../../shared/exios';
import {useCookiesCustom} from '../../../../hooks/useCookieCustom';

type typeFormData = {
    email: string;
    password: string;
    confirmPassword: string | null | undefined;
};

const initialData: typeFormData = {
    email: '',
    password: '',
    confirmPassword: '',
};

interface IChangePasswordModal {
    showModalPassword: boolean;
    setShowModalPassword: React.Dispatch<React.SetStateAction<boolean>>;
}

const ChangePasswordModal = ({
                                 showModalPassword,
                                 setShowModalPassword,
                             }: IChangePasswordModal): JSX.Element => {
    const {cookie} = useCookiesCustom();
    const [form] = Form.useForm();
    const [btnSubmitDisabled, setBtnSubmitDisabled] = useState(false);
    const [api] = notification.useNotification();
    const getCredentials = useCredentialsState((state) => state.getCredentials);

    const openNotification = ({type, message, description, duration}: any) => {
        if (type === 'success') {
            notification.success({
                icon: <IconNotificationSuccess/>,
                message: <span
                    style={{
                        fontSize: '16px',
                        fontWeight: '700',
                        color: '#FBFBFF',
                    }}>{message}</span>,
                description,
                duration,
                style: {background: '#2B2B36'},
                closeIcon: <CloseIcon/>,
            });
        } else {
            api.error({
                icon: <CheckMarkIcon style={{padding: '0 10px 0 0'}}/>,
                message,
                description,
                duration,
            });
        }
    };

    const submitFormData = () => {
        axios
            .post(`${EDENEX_ACCOUNT_URL}/edenex-account/api/password-change`, {
                    password: form.getFieldValue('newPassword'),
                    password_confirmation: form.getFieldValue('confirmPassword'),
                },
                {
                    headers: {
                        Authorization:
                            axios.defaults.headers['Authorization'] || `Bearer ${cookie['token']?.replace('Bearer ', '')}`,
                    },
                },
            )
            .then(() => {
                handleCancel();
                openNotification({
                    type: 'success',
                    message: t('titles.passwordChangedSuccessfully').toString(),
                    duration: 3,
                    description: '',
                });
            })
            .catch(() => {
                // Добавить уведомление
                openNotification({type: 'error', message: 'Password does not changed', duration: 3, description: ''});
            });
    };

    const handleCancel = () => {
        setIsValidate([]);
        setShowModalPassword(false);
        setBtnSubmitDisabled(true);
        if (cookie['token']) {
            getCredentials(cookie['token']).then();
        }
    };

    const [visibleTooltip, setVisibleTooltip] = useState(false);
    const [isValidate, setIsValidate] = useState<string[]>([]);
    const [formData, setFormData] = useState<typeFormData>(initialData);

    const onChange = (value: string, name: 'password' | 'confirmPassword') => {
        setFormData({...formData, [name]: value});
        if (name === 'password' || name === 'confirmPassword') {
            let validData = [];
            value.length >= 8 && value.length <= 32 && validData.push('lengthValid');
            value.match(/[A-Z]/g) !== null &&
            value.match(/[a-z]/g) !== null &&
            validData.push('registerValid');
            value.match(
                /^[A-Za-z0-9\(\)\"\«\»\|\.\,\:\;\?\!\*\+\%\-\<\>\@\[\]\{\}\_\{\}\$\#\~]+$/g,
            ) !== null && validData.push('langValid');
            value.match(/\d/g) !== null && validData.push('numValid');
            value.match(
                /[\(\)\"\«\»\|\.\,\:\;\?\!\*\+\%\-\<\>\@\[\]\{\}\_\{\}\$\#\~]/g,
            ) !== null && validData.push('symbolValid');
            setIsValidate(validData);
        }
    };

    const validateFields = async () => {
        try {
            await form.validateFields();
        } catch (err) {
        }
    };

    const handlePassword = (e: any) => {
        onChange(e.target.value, 'password');
    };

    const handleConfirmPassword = (e: any) => {
        onChange(e.target.value, 'confirmPassword');
    };


    const handleSubmit = () => {
        validateFields()
            .then(() => {
                if (form.getFieldsError()[0].errors.length || form.getFieldsError()[1].errors.length) {
                    setBtnSubmitDisabled(true);
                } else {
                    submitFormData();
                }
            });
    };
    const getPasswordRules = (value: string) => {
        const match = {
            message: t('inputs.errors.incorrectPassword').toString(),
            validator: (_: any, value: string) => {
                if (value === '' ||
                    (value.length >= 8 &&
                        value.length <= 32 &&
                        value.match(/[A-Z]/g) !== null &&
                        value.match(/[a-z]/g) !== null &&
                        value.match(
                            /^[A-Za-z0-9\(\)\"\«\»\|\.\,\:\;\?\!\*\+\%\-\<\>\@\[\]\{\}\_\{\}\$\#\~]+$/g,
                        ) !== null &&
                        value.match(/\d/g) !== null &&
                        value.match(
                            /[\(\)\"\«\»\|\.\,\:\;\?\!\*\+\%\-\<\>\@\[\]\{\}\_\{\}\$\#\~]/g,
                        ) !== null)
                ) {
                    return Promise.resolve();
                } else {
                    return Promise.reject();
                }
            },
        };

        if (!value) {
            return {};
        } else {
            return match;
        }
    };

    return (
        <>
            <CustomModal
                title={t('titles.changePassword')}
                open={showModalPassword}
                okText={t('buttons.change')}
                cancelText={t('buttons.cancel')}
                width={560}
                centered
                closeIcon={<Icon component={CloseIcon} style={{fontSize: '11px'}}
                                 onClick={() => setShowModalPassword(false)}/>}
                maskStyle={{
                    backdropFilter: 'blur(9px)',
                }}
                closable={true}
                destroyOnClose={true}
                footer={[
                    <>
                        <CustomSubmitButton
                            key='submit'
                            type='primary'
                            disabled={btnSubmitDisabled}
                            onClick={handleSubmit}
                        >
                            {t('buttons.change')}
                        </CustomSubmitButton>
                        <Button
                            style={{width: '180px'}}
                            key='cansel'
                            className={'CancelButton'}
                            onClick={handleCancel}
                        >
                            {t('buttons.cancel')}
                        </Button>
                    </>,
                ]}
            >
                {/*// TODO: Вернуть описание после решения*/}
                {/*<Typography.Text style={{ fontSize: '16px' }}>*/}
                {/*  {t('descriptions.changePassword')}*/}
                {/*</Typography.Text>*/}

                <Form
                    form={form}
                    preserve={false}
                    name='basic'
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        maxWidth: 600,
                        marginTop: '24px',
                    }}
                    autoComplete='off'
                    layout={'vertical'}
                    requiredMark={false}
                    onValuesChange={() => setBtnSubmitDisabled(false)}
                >
                    <Form.Item
                        label={
                            <label style={{color: '#FBFBFF'}}>
                                {t('inputs.labels.newPassword')}
                            </label>
                        }
                        name='newPassword'
                        // validateTrigger={'onSubmit'}
                        rules={
                            [
                                {
                                    required: true, message: form.getFieldValue('confirmPassword')
                                        ? t('inputs.labels.newPassword').toString()
                                        : t('inputs.required.required').toString(),
                                },
                                getPasswordRules(form.getFieldValue('newPassword')),
                            ]
                        }
                    >
                        <StyledPasswordInput
                            onFocus={() => setVisibleTooltip(true)}
                            onBlur={() => setVisibleTooltip(false)}
                            id='password'
                            onChange={handlePassword}
                            placeholder={t('inputs.placeholders.password').toString()}
                            name='password'
                            autoComplete='username password'
                        />
                    </Form.Item>
                    {visibleTooltip && (
                        <StyledCardAntd>
                            <StyledTitleCard>
                                {t('inputs.tooltips.passwordMustContain')}
                            </StyledTitleCard>
                            <StyledTextCard>
                                {isValidate.includes('lengthValid') ? (
                                    <CheckMarkIcon style={{padding: '0 10px 0 0'}}/>
                                ) : (
                                    <CrossMarkIcon style={{padding: '0 10px 0 0'}}/>
                                )}
                                <span>{t('inputs.tooltips.lettersCountInPassword')}</span>
                            </StyledTextCard>
                            <StyledTextCard>
                                {isValidate.includes('registerValid') ? (
                                    <CheckMarkIcon style={{padding: '0 10px 0 0'}}/>
                                ) : (
                                    <CrossMarkIcon style={{padding: '0 10px 0 0'}}/>
                                )}
                                <span>{t('inputs.tooltips.capitalizedLettersInPassword')}</span>
                            </StyledTextCard>
                            <StyledTextCard>
                                {isValidate.includes('langValid') ? (
                                    <CheckMarkIcon style={{padding: '0 10px 0 0'}}/>
                                ) : (
                                    <CrossMarkIcon style={{padding: '0 10px 0 0'}}/>
                                )}
                                <span>{t('inputs.tooltips.onlyLatinCharacters')}</span>
                            </StyledTextCard>
                            <StyledTextCard>
                                {isValidate.includes('numValid') ? (
                                    <CheckMarkIcon style={{padding: '0 10px 0 0'}}/>
                                ) : (
                                    <CrossMarkIcon style={{padding: '0 10px 0 0'}}/>
                                )}
                                <span>{t('inputs.tooltips.oneDigitMinimumInPassword')}</span>
                            </StyledTextCard>
                            <StyledTextCard>
                                {isValidate.includes('symbolValid') ? (
                                    <CheckMarkIcon style={{padding: '0 10px 0 0'}}/>
                                ) : (
                                    <CrossMarkIcon style={{padding: '0 10px 0 0'}}/>
                                )}
                                <span>
                  {t('inputs.tooltips.oneSpecialCharacterMinimumInPassword')}
                                    <br/>
                  () " " «» | . ,: ; ? ! * + % - &#60; &#62;
                                    <br/>@ [ ] {} _ {} $ # ~
                </span>
                            </StyledTextCard>
                        </StyledCardAntd>
                    )}
                    <br/>
                    <Form.Item
                        label={
                            <label style={{color: '#FBFBFF'}}>
                                {t('inputs.labels.repeatNewPassword')}
                            </label>
                        }
                        name='confirmPassword'
                        // validateTrigger={'onSubmit'}
                        rules={[
                            {
                                required: true,
                                message: form.getFieldValue('newPassword') ? t('inputs.placeholders.repeatPassword').toString() : t('inputs.required.required').toString(),
                            },
                            ({getFieldValue}) => ({
                                validator(rule, value) {
                                    if (!value || getFieldValue('newPassword') === value) {
                                        return Promise.resolve();
                                    }
                                    form
                                        .getFieldsError()
                                        .some((field) => (field.errors.length = 1));
                                    return Promise.reject(
                                        t('inputs.errors.passwordsDoesntMatch'),
                                    );
                                },
                            }),
                        ]}
                    >
                        <StyledPasswordInput
                            placeholder={t('inputs.placeholders.repeatPassword').toString()}
                            onChange={handleConfirmPassword}
                            id='confirmPassword'
                            name='confirmPassword'
                            autoComplete='new-password'
                        />
                    </Form.Item>
                </Form>
            </CustomModal>
        </>
    );
};
export {ChangePasswordModal};

const CustomModal = styled(Modal)`
    .ant-modal-footer {
        flex-direction: row;
        gap: 16px;
    }

    input::placeholder {
        color: #767687;
    }
`;

const CustomSubmitButton = styled(Button)`
    width: 60%;

    pointer-events: none;
    ${(props) =>
            props.disabled === false ? 'pointer-events: all' : 'pointer-events: none'}
`;

const StyledPasswordInput = styled(Input.Password)`
    height: 40px;
    border: 1px solid #474755;
    width: 100%;
    color: #fbfbff;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    border-radius: 8px;
    padding: 0 16px;
    background: none;
    transition: 0.2s;

    .ant-input-password-icon * {
        color: #767687;
    }
`;

const StyledCardAntd = styled(Card)`
    position: absolute;
    margin-left: -260px;
    margin-top: 120px;
    background-color: #2b2b36;
    border: none;
    padding-top: 0;

    .ant-card-body {
        padding: 16px;
    }
`;

const StyledTitleCard = styled.div`
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    color: #fbfbff;
    padding-bottom: 10px;
`;

const StyledTextCard = styled.div`
    display: flex;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    white-space: pre-wrap;
    color: #fbfbff;
    align-items: baseline;
    padding: 5px;
`;
