import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { styled } from "styled-components";
import { Button } from "antd";

export const ButtonStyled = styled(Button)`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: #141414;
  padding: 0;

  &:hover {
    border: 2px solid white ;
  }
`;
export const PlusOutlinedStyled = styled(PlusOutlined)`
  font-size: 16px;
`;

export const MinusOutlinedStyled = styled(MinusOutlined)`
  font-size: 16px;
`;

export const ControlsStyled = styled.div`
  position: absolute;
  top: 42%;
  right: 24px;
  z-index: 1000;
  display: flex;
  flex-direction: column;

  & > * {
    margin-bottom: 16px;
  }
`;