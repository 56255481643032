import {
  CopyableStyled,
  SupportEmailWrapperStyled,
} from './SupportEmail.styles';
import { useTranslation } from 'react-i18next';

export const SupportEmail = () => {
  const { t } = useTranslation();
  return (
    <SupportEmailWrapperStyled>
      {`${t('Поддержка')}: `}
      <CopyableStyled
        copyable={{ tooltips: [t('Скопировать'), t('Скопировано')] }}
      >{`admin@edenex.com`}</CopyableStyled>
    </SupportEmailWrapperStyled>
  );
};
