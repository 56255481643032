import styled from 'styled-components';
import { Badge, Dropdown } from 'antd';

export const DropdownStyled = styled(Dropdown)`
  color: white;
  height: 20px;
  width: 15px;
  padding: 5px;
  margin-right: 10px;
  box-sizing: content-box;
  svg {
    font-size: 20px;
  }
`;

export const BadgeStyled = styled(Badge)`
  .ant-badge-count {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 7px 4px;
  }
`;
