import { ModalStyled } from './ModalShowPointOnMap.styles';
import { useModalState } from '../../model/useModalState';
import { LeafletMap } from 'shared/ui/LeafletMap';
import { ModalKeys } from '../../model/enums';

export const ModalShowPointOnMap = () => {
  const isModalOpen = useModalState((state) =>
    state.isModalOpen(ModalKeys.ModalShowPointOnMap)
  );
  const closeModal = useModalState((state) => state.closeModal);

  const closeAddressModal = () => {
    closeModal(ModalKeys.ModalShowPointOnMap);
  };

  const modalOptions = useModalState(
    (state) => state.getModal(ModalKeys.ModalShowPointOnMap).options
  );

  const modalContainer =
    document.getElementById('modal-container') || document.body;

  return (
    <ModalStyled
      title={null}
      open={isModalOpen}
      onCancel={closeAddressModal}
      footer={null}
      maskClosable={false}
      getContainer={modalContainer}
      className="modal-select-address-on-map"
      
    >
      <LeafletMap
        initialPosition={[48.142, 17.13]}
        currentPoint={modalOptions?.currentPoint}
        displayAddress={modalOptions?.displayAddress}
      />
    </ModalStyled>
  );
};
