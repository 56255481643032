import { AvatarProps } from 'antd';
import { IUser } from 'interfaces';
import { useMemo } from 'react';
import CardTravel from '../userAvatar/assets/CardTravel.svg';
import User from '../userAvatar/assets/User.svg';
import Referee from '../userAvatar/assets/referee.svg';
import {
  AvatarStyled,
  TooltipStyled,
  UserAvatarIsRefereeStyled,
  UserAvatarStyled,
  UserAvatarWrapperStyled,
} from './assets/styles';
import { useTranslation } from 'react-i18next';
import Avatar from '../../assets/Avatar.svg';

type AvatarColors = 'default' | 'green' | 'blue';

interface IUserAvatarProps {
  user: IUser;
  color?: AvatarColors;
  size?: AvatarProps['size'];
  type?: 'company' | 'user' | 'exchange_point';
  isReferee: boolean;
  isAdmin: boolean;
}

const COLORS: Record<AvatarColors, string> = {
  default: 'linear-gradient(75.86deg, #6320E6 6.84%, #7B59FD 99.53%)',
  green: 'linear-gradient(80.91deg, #088473 0%, #12BFA8 100%)',
  blue: 'linear-gradient(270.4deg, #6E27F8 -85.22%, #20D9C0 164.99%)',
};

const UserAvatar = ({
  size,
  color,
  user,
  type,
  isReferee,
  isAdmin,
}: IUserAvatarProps) => {
  const { t } = useTranslation();

  const renderUserAvatar = useMemo(() => {
    const renderAvatarIcon = () => {
      console.log('renderAvatarIcon type', type);
      if (type === 'exchange_point') {
        return <UserAvatarStyled src={CardTravel} alt={'company'} />;
      } else if (type === 'user') {
        return <UserAvatarStyled src={User} alt={'user'} />;
      } else if (!type || isAdmin) {
        <UserAvatarStyled src={Avatar} alt={'Referee icon'} />;
      }
    };

    const commonStyles = {
      background: COLORS[color ?? 'default'],
      display: 'flex',
      alignItems: 'center',
      paddingBottom: '2px',
    };

    return (
      <UserAvatarWrapperStyled>
        {isReferee && (
          <TooltipStyled trigger={'hover'} title={t('чат.Рефери тултип')}>
            <UserAvatarIsRefereeStyled src={Referee} alt={'Referee icon'} />
          </TooltipStyled>
        )}
        <AvatarStyled shape="square" size={size} style={commonStyles}>
          {renderAvatarIcon()}
        </AvatarStyled>
      </UserAvatarWrapperStyled>
    );
  }, [user, color, size, type, isReferee, t]);

  return <>{renderUserAvatar}</>;
};

export { UserAvatar };
