import { FINMS_URL } from '../../packages/keycloak-client/constants';
import { useLayoutEffect, useState } from 'react';
import { notification } from 'antd';
import { useNavigate } from 'react-router-dom';
import { ROUTER_PATH } from '../../router/path';
import { useTranslation } from 'react-i18next';
import { axios } from '../../shared/exios';
import { useCookiesCustom } from '../../hooks/useCookieCustom';

type TConfirmInvite = {
  inviteToken: string
  token: string
}

const ConfirmInvite = () => {
  const {cookie, removeCookie} = useCookiesCustom();
  const [isConfirmed, setIsConfirmed] = useState<boolean>(false);
  const urlParams = new URLSearchParams(window.location.search);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [companyId, setCompanyId] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');

  const redirectAccessRefused = () => {
    // Перенаправление на страницу входа, если используется чужая ссылка
    console.log('redirectAccessRefused');
    setTimeout(() => {
      navigate(`${ROUTER_PATH.FormsPages.LOGIN}?redirect_url=${FINMS_URL}/redirect-page`);
    }, 4000);
  };

  const redirectToFinmsapp = () => {
    console.log('redirectToFinmsapp');
    setTimeout(() => {
      window.location.href = `${FINMS_URL}`;
    }, 4000);
  };

  const redirectToLoginPage = () => {
    console.log('если нет сессии перенаправление', `${ROUTER_PATH.FormsPages.LOGIN}?redirect_url=${FINMS_URL}/redirect-page`);
    setTimeout(() => {
      navigate(`${ROUTER_PATH.FormsPages.LOGIN}?redirect_url=${FINMS_URL}/redirect-page`);
    }, 4000);
  };

  const redirectToMyDeals = () => {
    setTimeout(() => {
      navigate(ROUTER_PATH.AuthRoutes.MY_DEALS);
    }, 4000);
  };

  const showInviteSuccessNotification = () => {
    notification.success({
      style: { background: '#2B2B36' },
      message: <span
        style={{
          fontSize: '16px',
          fontWeight: '700',
          color: '#FBFBFF',
        }}>{t('titles.success')}</span>,
      duration: 5,
      description: <span style={{ color: '#FBFBFF' }}>{t('descriptions.successfullyRegisteredInvite')}</span>,
    });
  };

  const showNotificationAccessRefused = () => {
    notification.error({
      style: { background: '#2B2B36' },
      duration: 5,
      message: <span
        style={{
          fontSize: '16px',
          fontWeight: '700',
          color: '#FBFBFF',
        }}>{t('titles.usingLinkAnotherEmail')}</span>,
      description: <span style={{ color: '#FBFBFF' }}>{t('descriptions.redirectLoginPage')}</span>,
    });
  };

  const showNotificationAlreadyHaveCompany = () => {
    notification.info({
      style: { background: '#2B2B36' },
      duration: 5,
      message: <span
        style={{
          fontSize: '16px',
          fontWeight: '700',
          color: '#FBFBFF',
        }}>{t('titles.youAlreadyHaveCompany')}</span>,
      description: <span style={{ color: '#FBFBFF' }}>{t('descriptions.redirectFinMS')}</span>,
    });
  };

  const showNotificationNotRegister = () => {
    notification.error({
      style: { background: '#2B2B36' },
      duration: 5,
      message: <span
        style={{
          fontSize: '16px',
          fontWeight: '700',
          color: '#FBFBFF',
        }}>{t('titles.youNotRegistred')}</span>,
      description: <span style={{ color: '#FBFBFF' }}>{t('descriptions.redirectLoginPage')}</span>,
    });
  };

  const confirmInvite = async ({ inviteToken, token }: TConfirmInvite) => {
    try {
      const res = await axios.post(`${FINMS_URL}/server/api/user-accounts`, {}, {
        headers: {
          'Authorization': `Bearer ${token?.replace('Bearer ', '')}`,
          'Invite-Token': inviteToken,
        },
      });
      console.log('ResInviteSuccess', res?.data?.data, res?.data?.data.company.id, res?.data?.data.company_id);
      const companyId = res?.data?.data?.company.id || res?.data?.data?.company_id;
      console.log('companyId', companyId);
      setCompanyId(companyId);
      return companyId;
    } catch (e: any) {
      console.error('ResInviteError', e);
      setErrorMessage(e?.response?.data?.message || 'Something went wrong');
      return e?.response?.data?.message;
    }
  };

  const removeInviteTokenFromCookie = () => {
    removeCookie('inviteToken');
  };

  const clearRedirectUrl = () => {
    console.log('clearRedirectUrl');
    localStorage.removeItem('redirectUrl');
  };

  const clearInviteToken = () => {
    console.log('clearInviteToken');
    localStorage.removeItem('inviteToken');
  };

  const clearEmailVerified = () => {
    console.log('clearEmailVerified');
    localStorage.removeItem('email_verified');
  };

  const getQueryInvite = async () => {
    const inviteToken = urlParams.get('inviteToken') || cookie.inviteToken || localStorage.getItem('inviteToken') || '';
    const token = `Bearer ${cookie.token?.replace('Bearer ', '')}`;
    console.info('break 1');
    if (!!inviteToken && inviteToken !== 'undefined' && !isConfirmed) {
      console.info('break 2');
      if (!!cookie.token) {
        console.info('break 3');
        await confirmInvite({ inviteToken, token }).then((id) => {
          console.info('break 4');
          console.log('companyId', companyId, id);
          if (!!companyId || !!id) {
            console.info('break 5');
            console.log('Перенаправление на finms, если есть сompany_id', companyId);
            setIsConfirmed(true);
            showInviteSuccessNotification();
            clearRedirectUrl();
            clearInviteToken();
            clearEmailVerified();
            redirectToFinmsapp();
          }
        }).catch(() => {
          console.info('break 6');
          if (errorMessage.includes('Access refused')) {
            console.info('break 7');
            showNotificationAccessRefused();
            redirectAccessRefused();
          }
          if (errorMessage.includes('No query results for model')) {
            console.info('break 8');
            console.log('No query results for model - уже есть компания');
            clearInviteToken();
            clearRedirectUrl();
            clearEmailVerified();
            removeInviteTokenFromCookie();
            showNotificationAlreadyHaveCompany();
            localStorage.setItem('inviteNotificationShown', 'true');
            redirectToFinmsapp();
          } else {
            console.info('break 9');
            if (!cookie.token) {
              console.info('break 10');
              showNotificationNotRegister();
              redirectToLoginPage();
            }
          }
        });
      } else if (!cookie.token) {
        console.info('break 11');
        showNotificationNotRegister();
        redirectToLoginPage();
      }
    } else {
      console.info('break 12');
      if ((!inviteToken || inviteToken === '' || inviteToken === 'undefined') && !!cookie.token) {
        console.info('break 13');
        clearRedirectUrl();
        clearInviteToken();
        redirectToMyDeals();
      } else if (!cookie.token) {
        console.info('break 14');
        clearInviteToken();
        clearRedirectUrl();
        console.log('redirect to login');
        setTimeout(() => {
          navigate(ROUTER_PATH.FormsPages.LOGIN);
        }, 4000);
      }
    }
  };

  useLayoutEffect(() => {
    getQueryInvite().then();
  }, []);

  return (
    <></>
  );
};

export default ConfirmInvite;
