import React, { useState } from 'react';
import styled from 'styled-components';

interface ICurrencyIconProps {
  code: string;
  type: 'bank' | 'card';
  heigth?: number;
  width?: number;
}

export const CurrencyIcon = ({
  code,
  type,
  heigth = 24,
  width = 24,
}: ICurrencyIconProps): JSX.Element => {
  const svgIconDynamic = `/assets/currencies/${code}.svg`;
  const defaultIconDynamic =
    type === 'bank'
      ? '/assets/currencies/BankIcon.svg'
      : '/assets/currencies/WalletIcon.svg';
  const [imgSrc, setImgSrc] = useState(svgIconDynamic);

  return (
    <ImageStyled
      $width={width}
      $height={heigth}
      src={imgSrc}
      alt={code}
      title={code}
      onError={(e) => setImgSrc(defaultIconDynamic)}
    />
  );
};

const ImageStyled = styled.img<{ $width: number; $height: number }>`
  width: ${(props) => props.$width}px;
  height: ${(props) => props.$height}px;
`;
