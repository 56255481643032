const notClsx = (...args: Array<string | string[] | boolean | null | undefined>) => {
  return args.flat().reduce<string>((className, v) => {
    if (typeof v === 'string' && v.length) {
      className += v + ' ';
    }

    return className
  }, '').slice(0 , -1)
}

export default notClsx
