import styled from 'styled-components';
import { Badge, Tabs } from 'antd';

export const BadgeStyled = styled(Badge)`
  margin-right: 16px;
  .ant-badge-count {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 7px 4px;
  }
`;

export const TabsStyled = styled(Tabs)`
  min-width: 100%;
  color: white;
  &:hover {
    color: rgba(22, 119, 255, 1);
  }
  .ant-tabs-tab {
    .ant-badge {
      color: rgba(255, 255, 255, 0.8);
    }
  }
  .ant-tabs-tab-active {
    .ant-badge {
      color: #1890ff;
    }
  }
`;
