import { useEffect, useState } from 'react';
import { ScreenBreakpoints } from 'shared/constants/enums';

// TODO: pick only one use resize
export const useResize = () => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return {
    width,
    isScreen350: width >= ScreenBreakpoints.SCREEN_350,
    isScreen360: width >= ScreenBreakpoints.SCREEN_360,
    isScreen380: width >= ScreenBreakpoints.SCREEN_380,
    isScreen480: width >= ScreenBreakpoints.SCREEN_480,
    isScreen575: width >= ScreenBreakpoints.SCREEN_575,
    isScreen720: width >= ScreenBreakpoints.SCREEN_720,
    isScreen767: width >= ScreenBreakpoints.SCREEN_767,
    isScreen991: width >= ScreenBreakpoints.SCREEN_991,
    isScreen1024: width > ScreenBreakpoints.SCREEN_1024,
    isScreen1099: width >= ScreenBreakpoints.SCREEN_1099,
    isScreen1199: width >= ScreenBreakpoints.SCREEN_1199,
    isScreen1280: width >= ScreenBreakpoints.SCREEN_1280,
    isScreen1300: width >= ScreenBreakpoints.SCREEN_1300,
    isScreen1400: width >= ScreenBreakpoints.SCREEN_1400,
    isScreen1500: width >= ScreenBreakpoints.SCREEN_1500,
    isScreen1600: width >= ScreenBreakpoints.SCREEN_1600,
    isScreen1700: width >= ScreenBreakpoints.SCREEN_1700,
  };
};
