import styled from "styled-components";

export const ListCard = styled.div`
  --list-card-green: #088473;
  --list-card-violet: #7B59FD;

  width: 100%;
  
  border-radius: 8px;
  border: 1px solid #474755;
  border-left: 3px solid var(--list-card-green);
  background: #1c1c27;

  &.is-buying-order {
    border-left-color: var(--list-card-violet);
  }
`