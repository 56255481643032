import styled from 'styled-components';

export const ChatContainerStyled = styled.div<{ $isReferee?: boolean }>`
  background-color: transparent;
  overflow: hidden;
  max-height: ${(props) => (props.$isReferee ? '100vh' : 'calc(100vh - 87px)')};
  height: ${(props) => (props.$isReferee ? '100vh' : '100%')};
  min-height: 85vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0;
`;
