import { Select, SelectProps } from 'antd';
import styled from 'styled-components';
import { TOptions } from './types';

export const FilterContainerStyled = styled.div``;

export const LabelStyled = styled.p`
  margin-bottom: 4px;
  color: #474755;
  font-size: 14px;
  line-height: 20px;
`;

export const SelectStyled = styled(Select)<SelectProps<string, TOptions>>`
  &.ant-select {
    &.ant-select-single {
      .ant-select-arrow {
        color: var(--deals-shaded-light);
        font-size: 16px;
      }
    }

    .ant-select-selector {
      padding-left: 16px;
    }

    .ant-select-clear {
      inset-inline-end: 16px;
      width: auto;
      height: auto;
      font-size: 16px;
      color: var(--deals-white);
      transform: translateY(-50%);
      margin-top: 0;
    }

    .ant-select-selection-overflow-item + .ant-select-selection-overflow-item {
      &:not(.ant-select-selection-overflow-item-rest) > span::before {
        content: ', ';
      }
    }

    .ant-select-selection-overflow-item-rest .ant-select-selection-item {
      background: transparent;
      margin: 0;
      padding: 0;

      .ant-select-selection-item-content::before {
        content: ', ';
      }
    }
  }
`;

export const SelectDropdownStyled = styled.div`
  .ant-select-item {
    .anticon {
      margin-right: 8px;
    }
  }
`;
