import { useWindowBreakpointState } from 'state/useWindowBreakpointState';

// TODO: pick only one useResize
export const useResize = () => {
  const state = useWindowBreakpointState()

  return {
    width: state.width,
    isScreen350: state.computed.isScreen350,
    isScreen360: state.computed.isScreen360,
    isScreen380: state.computed.isScreen380,
    isScreen480: state.computed.isScreen480,
    isScreen575: state.computed.isScreen575,
    isScreen720: state.computed.isScreen720,
    isScreen767: state.computed.isScreen767,
    isScreen991: state.computed.isScreen991,
    isScreen1024: state.computed.isScreen1024,
    isScreen1099: state.computed.isScreen1099,
    isScreen1199: state.computed.isScreen1199,
    isScreen1280: state.computed.isScreen1280,
    isScreen1300: state.computed.isScreen1300,
    isScreen1400: state.computed.isScreen1400,
    isScreen1500: state.computed.isScreen1500,
    isScreen1600: state.computed.isScreen1600,
    isScreen1700: state.computed.isScreen1700,
  };
};
