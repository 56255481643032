import create from 'zustand';
import { devtools } from 'zustand/middleware';
import { produce } from 'immer';
import { IChat } from '../../../interfaces';
import { getChats } from '../api/getPartnerChats';
import { getCountChats } from '../api/getCountChats';

export type TChatType = 'personal' | 'corporate';

interface IChatList {
  page: number;
  data: IChat[];
  totalPages: number;
  totalItems: number;
}

const initialChatList = {
  page: 1,
  data: [],
  totalPages: 0,
  totalItems: 0,
};

interface IChatAsideState {
  chatType: TChatType;
  isLoadingChats: boolean;
  chatCounts: {
    personal: number;
    corporate: number;
  };
  personalChatList: IChatList;
  corporateChatList: IChatList;
}
interface IChatAsideStore extends IChatAsideState {
  fetchChats: () => void;
  changeChatType: (newType: TChatType) => void;
  getChatCounts: () => void;
  fetchChatsAfterSwitch: () => void;
  resetChatLists: () => void;
  setDataToChatList: (
    chatsResponse:
      | { data: IChat[]; totalPages: number; totalItems: number }
      | undefined,
    page: number,
    totalItems: number
  ) => void;
}

export const useChatAsideState = create<IChatAsideStore>()(
  devtools(
    (set, get) => ({
      chatType: 'personal',
      isLoadingChats: false,
      chatCounts: {
        personal: 0,
        corporate: 0,
      },
      personalChatList: initialChatList,
      corporateChatList: initialChatList,
      fetchChats: async () => {
        const chatType = get().chatType;
        const page = get().corporateChatList.page;

        set({ isLoadingChats: true });
        const response = await getChats(chatType, page);

        get().setDataToChatList(response, page + 1, response?.totalItems ?? 0);

        set({ isLoadingChats: false });
      },
      changeChatType: (newType) => {
        set({
          chatType: newType,
        });
      },
      fetchChatsAfterSwitch: async () => {
        const chatType = get().chatType;
        const chatListType =
          chatType === 'personal' ? 'personalChatList' : 'corporateChatList';

        set({ [chatListType]: initialChatList });

        set({ isLoadingChats: true });

        const response = await getChats(chatType, 1);

        set({ isLoadingChats: false });

        get().setDataToChatList(response, 2, response?.totalItems ?? 0);
      },
      resetChatLists: () => {
        set({
          corporateChatList: initialChatList,
          personalChatList: initialChatList,
        });
      },
      setDataToChatList: (chatsResponse, page, totalItems) => {
        const chatType = get().chatType;
        const chatListType =
          chatType === 'personal' ? 'personalChatList' : 'corporateChatList';

        if (chatsResponse) {
          const { data, totalPages, totalItems } = chatsResponse;

          set({
            [chatListType]: {
              page,
              data: [...get()[chatListType].data, ...data],
              totalPages,
              totalItems,
            },
          });
        }
      },
      getChatCounts: async () => {
        const response = await getCountChats();

        set(
          produce((draft) => {
            draft.chatCounts.corporate =
              response?.exchange_point?.exchange_points.reduce(
                (prev, next) => prev + next.unread_message_count,
                0
              ) || 0;
            draft.chatCounts.personal = response?.user.unread_message_count
              ? response?.user.unread_message_count
              : 0;
          })
        );
      },
    }),
    {
      anonymousActionType: 'useChatAsideState action',
      name: 'useChatAsideState',
    }
  )
);
