import { Tabs } from "antd";
import { MEDIA_BREAKPOINT } from "shared/constants/media-breakpoints";
import styled from "styled-components";

export const BasicTabs = styled(Tabs)`
  color: #767687;

  .ant-tabs-nav {
    margin-bottom: 0;
    border-bottom: 1px solid #767687;
  }

  &.ant-tabs {
    .ant-tabs-tab {
      padding: 8px 4px;
      color: #767687;
      font-size: 18px;
      line-height: 1;
      font-weight: 700;

      &:hover {
        color: #DEDEE3
      }

      &.ant-tabs-tab-active {
        color: #DEDEE3;
      }

      &.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: #DEDEE3;
      }

      &.ant-tabs-tab-disabled {
        color: #444455;
      }

      & + .ant-tabs-tab {
        margin-left: 24px;
      }

      @media ${MEDIA_BREAKPOINT.MD} {
        padding: 16px 4px;

        & + .ant-tabs-tab {
          margin-left: 32px;
        }
      }
    }

    .ant-tabs-tab-btn:active {
      color: #444455;
    }
  }

  .ant-tabs-ink-bar {
    background: #ffffff;
  }
`;