import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export type TChatListTranslations = ReturnType<typeof useChatListItemTranslations>

const generateTranslation = (t: (key: string) => string) => ({
  sell: t('Продажа'),
  buy: t('Покупка'),
  referreeTooltip: t('Рефери тултип'),
  active: t('Активен'),
  inactive: t('Не активен'),
  offer: t('Оффер'),
  temporaryChat: t('Временный чат'),
  deal: t('Сделка'),
})

export const useChatListItemTranslations = () => {
  const { t, i18n } = useTranslation();

  const [translations, setTranslations] = useState(() => generateTranslation(t))

  useEffect(() => {
    setTranslations(generateTranslation(t))
  }, [t, i18n.language])

  return translations
}