import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Temp from '../assets/temp.svg';
import Exclamation from '../assets/exclamation.svg';
import InProgress from '../assets/inProgress.svg';
import Provision from '../assets/provision.svg';
import OutTime from '../assets/outTime.svg';
import Check from '../assets/check.svg';
import Ready from '../assets/ready.svg';
import Done from '../assets/done.svg';
import Cancel from '../assets/cancel.svg';

export type TIconDefinitions = ReturnType<typeof useIconDefinitions>;

const renderStatuses = (t: (key: string) => string) => ({
  temp: {
    icon: Temp,
    alt: 'Temp',
    text: '',
  },
  pending: {
    icon: InProgress,
    alt: 'pending',
    text: t('На рассмотрении'),
  },
  accepted: {
    icon: Check,
    alt: 'accepted',
    text: t('Принята'),
  },
  warranty_collateral: {
    icon: Provision,
    alt: 'warranty_collateral',
    text: t('Обеспечение гаранта'),
  },
  ready: {
    icon: Ready,
    alt: 'ready',
    text: t('Готова к обмену'),
  },
  completed: {
    icon: Done,
    alt: 'completed',
    text: t('Выполнена'),
  },
  waiting_for_fiat_payment: {
    icon: InProgress,
    alt: 'waiting_for_fiat_payment',
    text: t('Ожидание оплаты'),
  },
  expired: {
    icon: OutTime,
    alt: 'expired',
    text: t('Просрочена'),
  },
  on_argument: {
    icon: Exclamation,
    alt: 'on_argument',
    text: t('Открыт спор'),
  },
  cancelled: {
    icon: Cancel,
    alt: 'cancelled',
    text: t('Отменена'),
  },
});

export const useIconDefinitions = () => {
  const { t, i18n } = useTranslation();

  const [iconDefinitions, setIconsDefinitions] = useState(() =>
    renderStatuses(t)
  );

  useEffect(() => {
    setIconsDefinitions(renderStatuses(t));
  }, [t, i18n.language]);

  return iconDefinitions;
};
