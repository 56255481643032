import React, {useEffect, useState} from 'react';
import {RouterProvider} from './common/router';
import {ROUTES} from './router';
import {useLoginState} from './state/useLoginState';
import {notification} from 'antd';
import {IconNotificationSuccess} from './components/ui/icons';
import {CloseIcon} from './assets/img/icons/icons';
import {useTranslation} from 'react-i18next';
import FadeWrapper from './components/FadeWrapper';

function App() {
    const loader = document.querySelector('.ant-spin');
    const {t} = useTranslation();
    const loginError = useLoginState((state) => state.loginError);

    const [isVisible, setIsVisible] = useState(false);
    const [initialUpdate, setInitialUpdate] = useState(false);

    const translateErrorMessage = (loginError: string) => {
        const errors = {
            'Not found account': t('уведомление.АккаунтНеНайден!'),
            'invalid_grant': t('уведомление.НекорректныйЛогинИлиПароль!'),
            'invalid_grant_or_2fa': t('уведомление.НекорректныйЛогин,Пароль,2FA!'),
            'invalid_grant_or_Email': t('уведомление.НекорректныйЛогин,ПарольИлиEmail!'),
        };
        // @ts-ignore
        return errors[loginError];
    };

    useEffect(() => {
        if (!!loginError?.length) {
            notification.error({
                icon: <IconNotificationSuccess/>,
                message: <span
                    style={{
                        fontSize: '16px',
                        fontWeight: '700',
                        color: '#FBFBFF',
                    }}>{translateErrorMessage(loginError)}</span>,
                description: '',
                duration: 5,
                style: {background: '#2B2B36'},
                closeIcon: <CloseIcon/>,
            });
        }
    }, [loginError]);

    useEffect(() => {
        const handleRouteChange = () => {
            if (initialUpdate) {
                setIsVisible(true);
            } else {
                setInitialUpdate(true);
            }
        };

        // Подписка на событие изменения URL-адреса
        window.addEventListener('popstate', handleRouteChange);

        // Очистка подписки при размонтировании компонента
        return () => {
            window.removeEventListener('popstate', handleRouteChange);
        };
    }, [initialUpdate]);

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);

        // Для работы перехода по ссылке из меню, если есть redirectUrl, то чистить
        if (
            !!localStorage.getItem('redirectUrl') &&
            !!searchParams.get('fromMenu') &&
            searchParams.get('fromMenu') === 'true'
        ) {
            localStorage.removeItem('redirectUrl');
        }

        return () => {
            localStorage.removeItem('email_verified');
            localStorage.removeItem('redirectUrl');
            localStorage.removeItem('inviteToken');
        };
    }, []);

    if (loader) {
        loader.remove();
    }

    return (
        <FadeWrapper isVisible={isVisible}>
            <RouterProvider routes={ROUTES}/>
        </FadeWrapper>
    );
}

export default App;
