import styled from 'styled-components';
import { Badge, Tabs } from 'antd';

export const BadgeStyled = styled(Badge).attrs(() => ({
  size: 'default',
  color: 'linear-gradient(45deg, #088473 0%, #12BFA8 100%)',
  showZero: true,
  offset: [19, 5],
}))`
  margin-right: 16px;
  gap: 12px;
  display: flex;
  .anticon {
    margin-right: 0 !important;
  }
  .ant-badge-count {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 7px 4px;
  }
`;

export const TabsStyled = styled(Tabs)`
  width: 100%;
  .ant-tabs-tab {
    padding-right: 10px;
    margin: 0 0 0 16px !important;
  }
  cursor: pointer;
  color: white;
  &:hover {
    color: rgba(22, 119, 255, 1);
  }
  .ant-tabs-tab {
    padding: 14px 10px 18px;
    .ant-badge {
      color: rgba(255, 255, 255, 0.8);
    }
  }
  .ant-tabs-tab-active {
    .ant-badge {
      color: #1890ff;
    }
  }
`;
