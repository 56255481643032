import React, { lazy, useEffect, useMemo } from 'react';
import { Header } from 'antd/es/layout/layout';
import styled from 'styled-components';
import {
  EXCHANGE_MAP_URL,
  FINMS_URL,
} from '../../../../../packages/keycloak-client/constants';
import { MenuProps } from 'antd';
import i18n, { t } from 'i18next';
import { LinksDropdown } from './components/links-dropdown';
import { getFinmsMe } from '../../../../../shared/api/me';
import { useAuthState } from '../../../../../state/useAuthState';
import Icon, { MenuOutlined, StarOutlined } from '@ant-design/icons';
import UserBalance from './components/user-balance';
import { NotificationsAsideBell } from '../../../../../entities/notificationAside/ui/NotificationsAsideBell/NotificationsAsideBell';
import { ChatAsideBell } from '../../../../../widgets/chatAside/ui/ChatAsideBell/ChatAsideBell';
import { useResize } from 'shared/hooks/use-resize';
import { SideBarLogo } from 'assets/img/logo/logo';
import { RenderModal } from 'shared/ui/RenderModal';
import { ModalKeys } from 'shared/ui/RenderModal/model/enums';
import { Link } from 'react-router-dom';
const UserDropdown = lazy(() =>
  import('./components/user-dropdown').then((module) => ({
    default: module.UserDropdown,
  }))
);

const CustomHeader: React.FC = () => {
  const { setUserFinms, userFinmsApi, getUserProfileFromAPI } = useAuthState();
  const { isScreen720, isScreen1280 } = useResize();
  const items: MenuProps['items'] = useMemo(() => {
    const elems = [
      {
        key: 'Platform',
        label: (
          <>
            <LabelContainerStyled>
              <StarOutlined />
              <span>Platform</span>
            </LabelContainerStyled>
            <DescriptionStyled>
              {t('Кошелёк запятая сделки и настройки аккаунта')}
            </DescriptionStyled>
          </>
        ),
        style: {},
      },
      {
        key: 'Exchange_Map',
        label: (
          <a href={EXCHANGE_MAP_URL}>
            <LabelContainerStyled>
              <StarOutlined />
              <span>Exchange_Map</span>
            </LabelContainerStyled>
            <DescriptionStyled>
              {t('Сделки с гарантом запятая мониторинг и партнерство')}
            </DescriptionStyled>
          </a>
        ),
        style: {},
      },
    ];
    if (userFinmsApi.company) {
      elems.push({
        key: 'Fin_MS',
        label: (
          <a href={FINMS_URL}>
            <LabelContainerStyled>
              <StarOutlined />
              <span>Fin_MS</span>
            </LabelContainerStyled>
            <DescriptionStyled>
              {t('Управление пунктами обмена')}
            </DescriptionStyled>
          </a>
        ),
        style: {},
      });
    }
    return elems;
  }, [userFinmsApi, i18n.language]);

  useEffect(() => {
    getUserProfileFromAPI();
    getFinmsMe()
      .then((data) => {
        setUserFinms(data);
      })
      .catch((e) => console.log(e));
  }, []);

  const isShowChat = true;

  return (
    <HeaderWrapper>
      {!isScreen1280 && (
        <LinkStyled to={'/'}>
          <Icon component={SideBarLogo} />
        </LinkStyled>
      )}

      {/* <HeaderLinks>
          <a href={`${STOCK_EXCHANGE_URL}/cryptocurrency-exchange`}>
            <Typography.Text>{t('header.menu.exchange')}</Typography.Text>
          </a>
          <a href={`${FINMS_URL}`}>
            <Typography.Text>Fin_MS</Typography.Text>
          </a>
          <a href={`${EXCHANGE_MAP_URL}`}>
            <Typography.Text>EXCHANGE_MAP</Typography.Text>
          </a>
        </HeaderLinks> */}
      <HeaderButtons>
        {isScreen720 && <UserBalance />}
        {isShowChat && <ChatAsideBell />}
        <NotificationsAsideBell />
        <UserDropdown />
        {isScreen1280 && (
          <FlexStyled>
            <DividerStyled />
            <LinksDropdown items={items} />
          </FlexStyled>
        )}
        {!isScreen1280 && (
          <BurgerButton>
            <MenuOutlined style={{ fontSize: 14, color: '#FFFFFFCC' }} />
          </BurgerButton>
        )}
      </HeaderButtons>
      <RenderModal currentModalKey={ModalKeys.ModalUserMenu} isShowHeader />
    </HeaderWrapper>
  );
};

export { CustomHeader };

const LinkStyled = styled(Link)`
  &&& {
    line-height: 0;
  }
`;

const BurgerButton = styled.button`
  padding: 11px;
  border: 1px solid #ffffff26;
  line-height: 0;
  border-radius: 8px;
  cursor: pointer;
  background-color: transparent;
`;

const HeaderWrapper = styled(Header)`
  &&& {
    padding-inline: 16px;
    height: 72px;
    background-color: #14141f;
  }

  border-bottom: 1px solid;
  line-height: 0;
  border-color: rgba(65, 61, 80, 0.5);
  display: flex;
  justify-content: end;
  align-items: center;

  @media screen and (width < 1280px) {
    justify-content: space-between;
  }
`;

const HeaderContent = styled.div`
  height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const HeaderLinks = styled.div`
  height: 32px;
  display: flex;
  gap: 56px;
  cursor: pointer;
  padding: 0;
  align-items: center;
`;

const HeaderButtons = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;
const LabelContainerStyled = styled.div`
  display: flex;
  gap: 8px;
`;
const DescriptionStyled = styled.p`
  color: #ffffffcc;
  font-size: 12px;
  margin: 0;
`;
const DividerStyled = styled.div`
  height: 24px;
  width: 1px;
  background: #ffffff26;
  display: flex;
`;
const FlexStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;
