import React from 'react';
import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import { ROUTER_PATH } from '../../../../../router/path';
import {
  DealsIcon,
  CommonProfileControlIcon,
  SecurityIcon,
  WalletIcon,
  WalletMenuIcon
} from '../../../../../assets/img/icons/icon';
import Icon from '@ant-design/icons';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuthState } from '../../../../../state/useAuthState';
import { useNavigate } from 'react-router-dom';
import { useCookiesCustom } from '../../../../../hooks/useCookieCustom';
import { GearIcon } from '../../../../../assets/img/icons/icons';

type IconComponent =
  | React.ComponentType<
      CustomIconComponentProps | React.SVGProps<SVGSVGElement>
    >
  | React.ForwardRefExoticComponent<CustomIconComponentProps>;

interface IBaseSideElement {
  pathKey: string;
  iconComponent?: (color?: string) => JSX.Element;
  title: string;
}

interface ISidebarElement {
  pathKey: string;
  iconComponent?: (color?: string) => JSX.Element;
  title: string;
  childerns?: IBaseSideElement[];
  onClick?: () => void;
}

interface ISidebarCategories {
  name: string;
  elements: ISidebarElement[];
}

const DrawSideBarMenuElement = (
  title: string,
  icon?: IconComponent,
  isSelected?: boolean,
  isSubmenu?: boolean
) => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
      paddingLeft: 10,
    }}
  >
    {icon && <Icon component={icon} size={!isSubmenu ? 16 : 10} />}
    <Typography.Text
      style={{ marginInlineStart: 0, color: isSelected ? '' : '#767687' }}
    >
      {title}
    </Typography.Text>
  </div>
);

export const useGetSideBarElements = () => {
  const { t } = useTranslation();
  const logout = useAuthState((state) => state.logout);
  const navigate = useNavigate();
  const { removeCookie } = useCookiesCustom();

  const PROFILE_ELEMENTS: ISidebarElement[] = [
    {
      pathKey: ROUTER_PATH.AuthRoutes.WALLET,
      iconComponent: WalletMenuIcon,
      title: t('МОЙ КОШЕЛЁК'),
      childerns: [
        {
          pathKey: ROUTER_PATH.AuthRoutes.TRANSACTIONS.replace(':coin', 'btc'),
          title: t('Транзакции'),
        },
      ],
    },
    {
      pathKey: ROUTER_PATH.AuthRoutes.MY_DEALS,
      iconComponent: DealsIcon,
      title: t('МОИ СДЕЛКИ'),
    },
    {
      pathKey: ROUTER_PATH.AuthRoutes.ACCOUNT_SETTINGS_INDEX,
      iconComponent: CommonProfileControlIcon,
      title: t('ДАННЫЕ АККАУНТА'),
    },
    {
      pathKey: ROUTER_PATH.AuthRoutes.SAFETY_INDEX,
      iconComponent: SecurityIcon,
      title: t('БЕЗОПАСНОСТЬ'),
      // childerns: [
      // TODO: Вернуть 2FA
      // {
      //   pathKey: ROUTER_PATH.AuthRoutes.SAFETY_2FA,
      //   title: '2FA',
      // },
      // {
      //   // TODO: Добавить защиту по IP модуль
      //   pathKey: '',
      //   title: 'Защита по IP-адресу',
      // },
      // TODO: Добавить раздел devices
      // {
      //   pathKey: ROUTER_PATH.AuthRoutes.SAFETY_DEVICES,
      //   title: t('sidebar.profile.safety.devices'),
      // },
      // ],
    },
    // {
    //   pathKey: ROUTER_PATH.AuthRoutes.OPTIONS,
    //   iconComponent: GearIcon,
    //   title: t('НАСТРОЙКИ'),
    // },
  ];

  const SIDEBAR_CATEGORIES: ISidebarCategories[] = [
    {
      name: '',
      elements: PROFILE_ELEMENTS,
    },
  ];

  const BOTTOM_SIDEBAR_ELEMENTS: ISidebarElement[] = [
    // TODO: Добавить ссылку на FAQ
    // TODO: Добавить ссылку на Help
    // {
    //   pathKey: '',
    //   title: 'FAQ',
    //   iconComponent: InfoCircle,
    // },
    // {
    //   pathKey: '',
    //   title: t('bottomSidebar.help'),
    //   iconComponent: QuestionCircleIcon,
    // },
    // {
    //   pathKey: `${BASE_MAIN_URL}/main`,
    //   title: t('bottomSidebar.exit'),
    //   iconComponent: LogoutIcon,
    //   onClick: () => logout(),
    // },
  ];

  return {
    DrawSideBarMenuElement,
    SIDEBAR_CATEGORIES,
    BOTTOM_SIDEBAR_ELEMENTS,
  };
};
