import { PropsWithChildren } from 'react';
import { ChatContainerStyled } from './assets/styles';
import { ChatProvider } from '../context';
import { IChatRepository, IChatSocket } from '../../interfaces';

interface IChatContainerProps extends PropsWithChildren {
  isReferee?: boolean;
}

const ChatContainer = (props: IChatContainerProps) => {
  return (
    <ChatProvider socket={{} as IChatSocket} repository={{} as IChatRepository}>
      <ChatContainerStyled $isReferee={props.isReferee}>
        {props.children}
      </ChatContainerStyled>
    </ChatProvider>
  );
};

export { ChatContainer };
