import styled from 'styled-components';

export const ChatContainerStyled = styled.div`
  position: relative;
  flex-grow: 2;

  display: flex;
  flex-direction: column;
  //max-height: 55vh;
`;
