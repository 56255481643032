import { MEDIA_BREAKPOINT } from "shared/constants/media-breakpoints";
import styled from "styled-components";

export const MobileLayoutStyled = styled.div`
  padding: 12px;

  @media ${MEDIA_BREAKPOINT.SM} {
    padding: 12px 16px;
  }
`

export const TableLayoutHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const TableLayoutBody = styled.div`
  display: grid;
  grid-template-columns: auto;
  gap: 8px 12px;
  margin-top: 12px;

  @media ${MEDIA_BREAKPOINT.MD} {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
`
export const TableLayoutColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  min-width: 0;
`