import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Skeleton } from 'antd';

export const NotificationEmptyStyled = styled.div`
  background-color: #14141f;
  margin-top: 20%;
  min-height: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 35px;
  text-align: center;
`;
export const EmptyNotificationsBlockStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
export const EmptyNotificationsTextStyled = styled.div`
  color: rgba(255, 255, 255, 0.5);
  font-weight: 400;
  font-size: 20px;
`;
export const LinkInEmptyStyled = styled(Link)`
  font-weight: 400;
  font-size: 16px;
  color: rgba(22, 119, 255, 1);
`;

export const WrapSkeletonStyled = styled(Skeleton.Input)`
  display: block;
  &&& {
    width: 100%;
    height: 100px;
    min-height: 100px;
  }
  margin-bottom: 15px;
`;
