import { devtools } from 'zustand/middleware';
import { produce } from 'immer';
import { create } from 'zustand';
import { ModalKeys } from './enums';
interface IModalItemState {
  key: string;
  options: any;
  isOpen: boolean;
}

export type TModalState = {
  modals: { [key: string]: IModalItemState };
  openModal: (key: string, options?: object | null) => void;
  closeModal: (key: string) => void;
  isModalOpen: (key: string) => boolean;
  getModal: (key: ModalKeys) => IModalItemState;
  getModals: () => void;
};

export const useModalState = create<TModalState>()(
  devtools(
    (set, get) => ({
      modals: {
        [ModalKeys.ModalCancelTransaction]: {
          // TODO надо будет выпилить эти модалки попозже
          key: ModalKeys.ModalCancelTransaction,
          options: {
            id: 0,
            isBuyingCrypto: false,
            cryptoCurrencyCode: '',
            refetch: () => undefined,
            callback: () => undefined,
          },
          isOpen: false,
        },
        [ModalKeys.ModalConfirmReceiptOfCash]: {
          key: ModalKeys.ModalConfirmReceiptOfCash,
          options: {
            id: 0,
            isBuyingCrypto: false,
            currency: 'USDT',
            refetch: () => undefined,
          },
          isOpen: false,
        },
        [ModalKeys.ModalOpenDispute]: {
          key: ModalKeys.ModalOpenDispute,
          options: {
            id: 0,
            isBuyingCrypto: false,
            currency: 'USDT',
            refetch: () => undefined,
          },
          isOpen: false,
        },
        [ModalKeys.ModalShowPointOnMap]: {
          key: ModalKeys.ModalShowPointOnMap,
          options: {
            currentPoint: undefined,
            displayAddress: '',
          },
          isOpen: false,
        },
        [ModalKeys.ModalConfirmPayment]: {
          key: ModalKeys.ModalConfirmPayment,
          options: {
            id: 0,
            isBuyingCrypto: false,
            currency: 'USDT',
            refetch: () => undefined,
            deal: {},
          },
          isOpen: false,
        },
        [ModalKeys.ModalUserMenu]: {
          key: ModalKeys.ModalUserMenu,
          options: {
            items: [],
          },
          isOpen: false,
        },
        [ModalKeys.ModalDealsFilters]: {
          key: ModalKeys.ModalShowPointOnMap,
          options: null,
          isOpen: true,
        }
      },
      openModal: (key, options) =>
        set(
          produce((draft) => {
            draft.modals[key] = { ...draft.modals[key], isOpen: true, options };
          }),
          false,
          {
            type: 'useModalState => openModal',
          }
        ),
      closeModal: (key) =>
        set(
          produce((draft) => {
            draft.modals[key] = {
              ...draft.modals[key],
              isOpen: false,
              options: null,
            };
          }),
          false,
          {
            type: 'useModalState => closeModal',
          }
        ),
      isModalOpen: (key) => get().modals[key]?.isOpen,
      getModal: (key: ModalKeys) => get().modals[key],
      getModals: () => get().modals,
    }),
    {
      name: 'useModalState',
      anonymousActionType: 'useModalState action',
    }
  )
);
