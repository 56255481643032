import { Switch } from 'antd';
import { MEDIA_BREAKPOINT } from 'shared/constants/media-breakpoints';
import styled, { css } from 'styled-components';

export const ContainerStyled = styled.div``;

export const FilterValuesWrap = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: nowrap;
  align-items: center;
  overflow-x: scroll;

  @media ${MEDIA_BREAKPOINT.MD} {
    overflow-x: auto;
    flex-wrap: wrap;
  }
`;

export const ButtonsContainerStyled = styled.div`
  display: flex;
  align-items: end;
  justify-content: flex-end;
  grid-column-end: -1;
`;

export const CheckboxesContainerStyled = styled.div`
  display: flex;
  justify-content: center;
  height: 40px;
  align-items: center;
`;

export const SwitchContainerStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;
export const StyledSwitch = styled(Switch)<{ $checked: boolean }>`
  &&&& {
    .focus,
    .hover,
    .active {
      background: linear-gradient(90deg, #6320e5 0%, #7b59fd 100%);
    }
    background: ${({ $checked }) => ($checked ? '#6320e5' : '#474755')};
  }
`;

export const AdditionalActionsAreaStyled = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 24px;
`;

export const MainFiltersStyled = styled.div`
  display: grid;
  gap: 16px 24px;
  align-items: end;
  @media ${MEDIA_BREAKPOINT.MD} {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @media ${MEDIA_BREAKPOINT.XL} {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-template-rows: 80px;
    height: 80px;
    overflow-y: hidden;
  }
`;
