import styled from "styled-components";

export const ChatListItemParticipant = styled.div`
  display: flex;
  min-width: 0;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: start;

  font-family: var(--chat-list-font-inter);
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;

  .chat-list-icon {
    color: rgba(255, 255, 255, 0.5)
  }
`;

export const ChatListParticipantName = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`
