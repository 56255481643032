import {styled} from "styled-components";

export const ChatListItemsEmptyStyled = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
`;

export const ChatListEmptyTitleStyled = styled.div`
    font-family: SF Pro Text, sans-serif;
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    text-align: left;
    color: #FFFFFF80;
`;
