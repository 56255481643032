import { RouteObject } from 'react-router-dom';
import { lazy } from 'react';
import { LoadComponent } from '../../../common/router';
import { ROUTER_PATH } from '../../path';

const NotificationsSettings = LoadComponent(
  lazy(() => import('../../../pages/notifications-settings/NotificationsSettings'))
);
const HistoryShow = LoadComponent(
  lazy(() => import('../../../pages/coin-account/show'))
);
const SafetyIndexPage = LoadComponent(
  lazy(() => import('../../../pages/safety'))
);
const ConfirmEmail = LoadComponent(
  lazy(() => import('../../../pages/confirm-email/ConfirmEmail'))
);
const GoogleAuthenticator = LoadComponent(
  lazy(() => import('../../../pages/safety/2fa-google'))
);
const DevicesManagement = LoadComponent(
  lazy(() => import('../../../pages/safety/devices-management'))
);
const IpProtection = LoadComponent(
  lazy(() => import('../../../pages/safety/ip-protection'))
);
const ChangeEmailPage = LoadComponent(
  lazy(() => import('../../../pages/safety/change-email-page/ChangeEmailPage'))
);

const MyDeals = LoadComponent(
  lazy(() => import('../../../pages/my-deals/MyDeals'))
);

const DealInfoPage = LoadComponent(
  lazy(() => import('../../../pages/deal-info/DealInfoPage'))
);

const WalletPage = LoadComponent(
  lazy(() => import('../../../pages/walletCopy/ui/Wallet/Wallet'))
);
const NotificationsPage = LoadComponent(
  lazy(() => import('../../../pages/notifications/Notifications'))
);

const SAFETY_ROUTES: RouteObject[] = [
  { path: ROUTER_PATH.AuthRoutes.CHANGE_EMAIL, element: <ChangeEmailPage /> },
  { path: ROUTER_PATH.AuthRoutes.SAFETY_INDEX, element: <SafetyIndexPage /> },
  { path: ROUTER_PATH.AuthRoutes.MY_DEALS, element: <MyDeals /> },
  { path: ROUTER_PATH.AuthRoutes.WALLET, element: <WalletPage/> },
  { path: ROUTER_PATH.AuthRoutes.SETTINGS_NOTIFICATION, element: <NotificationsSettings/> },
  { path: ROUTER_PATH.AuthRoutes.TRANSACTIONS, element: <HistoryShow /> },
  { path: ROUTER_PATH.AuthRoutes.SAFETY_2FA, element: <GoogleAuthenticator /> },
  {
    path: ROUTER_PATH.AuthRoutes.SAFETY_DEVICES,
    element: <DevicesManagement />,
  },
  {
    path: ROUTER_PATH.AuthRoutes.SAFETY_IP_PROTECTION,
    element: <IpProtection />,
  },
  {
    path: ROUTER_PATH.AuthRoutes.CONFIRM_EMAIL,
    element: <ConfirmEmail />,
  },
  {
    path: ROUTER_PATH.AuthRoutes.DEAL_INFO,
    element: <DealInfoPage />,
  },
  {
    path: ROUTER_PATH.AuthRoutes.NOTIFICATIONS,
    element: <NotificationsPage />,
  },
];

export { SAFETY_ROUTES };
