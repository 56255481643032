import { Button } from 'antd';
import { MEDIA_BREAKPOINT } from 'shared/constants/media-breakpoints';
import styled from 'styled-components';

export const ChatListHeaderTitleStyled = styled.h1`
  margin: 0;
  font-family: SF Pro Text, sans-serif;
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  text-align: left;
  color: #ffffff;

  @media ${MEDIA_BREAKPOINT.MD} {
    font-size: 38px;
    line-height: 46px;
  }
`;

export const ChatListHeaderStyled = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4px;

  @media ${MEDIA_BREAKPOINT.MD} {
    margin-bottom: 8px;
  }
`;

export const CloseButton = styled(Button)`
  margin-left: auto;

  &.ant-btn.ant-btn-icon-only {
    height: 32px;
    width: 32px;
    padding: 4px 0;
    font-size: 16px;

    &>span {
      transform: none;
    }

    @media ${MEDIA_BREAKPOINT.MD} {
      width: 40px;
      height: 40px;
    }
  }
`
