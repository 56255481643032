import create from 'zustand';
import {produce} from 'immer';
import {devtools} from 'zustand/middleware';
import {
  getNotificationAside,
  getNotificationAsideCount,
  readAllNotifications,
  readCurrentNotification,
} from '../api/getNotificationAside';
import { IGetCountResponse, INotification, TNotificationsFilters, TNotificationsTabs } from './types';
import { AxiosResponse } from 'axios';

type TNotificationAsideState = {
  notifications: INotification[];
  setNotifications: (filters?: TNotificationsFilters) => void;
  totalNotificationsCount: number;
  totalNotificationsCorporateCount: number;
  totalNotificationsMyDealsCount: number;
  setTotalNotificationsCount: () => void;
  activeTab: TNotificationsTabs;
  setActiveTab: (tab: TNotificationsTabs) => void;
  readAllNotifications: () => void;
  setReadNotification: (id: number, type: string) => void;
  isNotificationsLoading: boolean;
  setIsNotificationsLoading: (isLoading: boolean) => void;
};

export const useNotificationAsideState = create<TNotificationAsideState>()(
  devtools(
    (set, get) => ({
      notifications: [],
      activeTab: 'all',
      totalNotificationsCount: 0,
      totalNotificationsCorporateCount: 0,
      totalNotificationsMyDealsCount: 0,
      isNotificationsLoading: true,
      setIsNotificationsLoading: (isLoading: boolean) => {
        set(
          produce((draft) => {
            draft.isNotificationsLoading = isLoading;
          })
        );
      },
      setNotifications: async (filters: TNotificationsFilters | undefined) => {
        set(
          produce((draft) => {
            draft.isNotificationsLoading = true;
          })
        );
        await getNotificationAside(filters)
          .then((res: AxiosResponse<INotification[], any>) => {
            set(
              produce((draft) => {
                draft.notifications = res.data;
              })
            );
        }
        ).finally(() => {
            set(
              produce((draft) => {
                draft.isNotificationsLoading = false;
              })
            );
        })
      },
      setActiveTab: async (tab: string) => {
        set(
          produce((draft) => {
            draft.activeTab = tab;
          })
        );
      },
      setTotalNotificationsCount: async () => {
        set(
          produce((draft) => {
            draft.isNotificationsLoading = true;
          })
        );
        await getNotificationAsideCount()
          .then((res: AxiosResponse<IGetCountResponse, any>) => {
            if (res) {
              set(
                produce((draft) => {
                  draft.totalNotificationsCount = Number(res.data.corporate?.count) + Number(res.data.my_deals?.count);
                  draft.totalNotificationsCorporateCount = res.data.corporate?.count;
                  draft.totalNotificationsMyDealsCount = res.data?.my_deals?.count;
                })
              );
            }
          })
          .finally(() => {
            set(
              produce((draft) => {
                draft.isNotificationsLoading = false;
              })
            );
          })
      },
      readAllNotifications: async () => {
        set(
          produce((draft) => {
            draft.isNotificationsLoading = true;
          })
        );
        await readAllNotifications()
          .then(() => {
            set(
              produce((draft) => {
                draft.totalNotificationsCount = 0;
              })
            );
          })
          .finally(() => {
            set(
              produce((draft) => {
                draft.isNotificationsLoading = false;
              })
            );
          })
      },
      setReadNotification: async (id: number, type: string) => {
        set(
          produce((draft) => {
            draft.isNotificationsLoading = true;
          })
        );
        await readCurrentNotification(id)
          .finally(() => {
            if (type === 'corporate') {
              set(
                produce((draft) => {
                  draft.isNotificationsLoading = false;
                  draft.totalNotificationsCount = get().totalNotificationsCount - 1;
                  draft.totalNotificationsCorporateCount = get().totalNotificationsCorporateCount - 1;
                  draft.setNotifications([
                    {
                    field: 'is_read',
                    operator: '=',
                    value: false,
                    },
                    {
                      field: 'type',
                      operator: '=',
                      value: type,
                    }]);
                })
              );
            }
            if (type === 'my_deals') {
              set(
                produce((draft) => {
                  draft.isNotificationsLoading = false;
                  draft.totalNotificationsCount = get().totalNotificationsCount - 1;
                  draft.totalNotificationsMyDealsCount = get().totalNotificationsMyDealsCount - 1;
                  draft.setNotifications([
                    {
                      field: 'is_read',
                      operator: '=',
                      value: false,
                    },
                    {
                      field: 'type',
                      operator: '=',
                      value: type,
                    }
                  ])
                })
              );
            }
          })
      },
    }),
    {
      anonymousActionType: 'useNotificationAsideState action',
      name: 'useNotificationAsideState',
    }
  )
);
