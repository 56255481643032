import { axios } from '../exios';
import { FINMS_URL_API } from '../../packages/keycloak-client/constants';
import { TUserFinms } from '../../state/useAuthState';

type TUserFinmsResponse = {
  id: number;
  uid: string;
  company_id: number;
  role_id: number;
  username: string;
  firstname: string;
  lastname: string;
  phone_number: string;
  email: string;
  status: string;
  contact_info: string | null;
  created_at: string;
  updated_at: string;
  language: string;
  total_debt: string;
  total_debt_all_course_exist: boolean;
  role: {
    id: number;
    name: string;
    description: string;
    company_id: number;
    created_at: string;
    updated_at: string;
  };
  company: {
    id: number;
    name: string;
    created_at: string;
    updated_at: string;
    currency_key: string;
    avatar: string;
    country: string | null;
    description: string | null;
    currency: {
      id: number;
      company_id: number;
      name: string;
      shortname: string;
      zeros_number: number;
      status: string;
      type: string;
      created_at: string | null;
      updated_at: string | null;
      network: string | null;
    };
  };
};

export const getFinmsMe = async (): Promise<TUserFinms> => {
  const res = await axios.get<TUserFinmsResponse[]>(
    `${FINMS_URL_API}/server/api/me?include=role`
  );
  const user = res?.data[0];
  return {
    id: user.id,
    uid: user.uid,
    companyId: user.company_id,
    roleId: user.role_id,
    username: user.username,
    firstname: user.firstname,
    lastname: user.lastname,
    phoneNumber: user.phone_number,
    email: user.email,
    status: user.status,
    contactInfo: user.contact_info,
    createdAt: user.created_at,
    updatedAt: user.updated_at,
    language: user.language,
    totalDebt: user.total_debt,
    totalDebtAllCourseExist: user.total_debt_all_course_exist,
    role: {
      id: user.role.id,
      name: user.role.name,
      description: user.role.description,
      companyId: user.role.company_id,
      createdAt: user.role.created_at,
      updatedAt: user.role.updated_at,
    },
    company: {
      id: user.company.id,
      name: user.company.name,
      createdAt: user.company.created_at,
      updatedAt: user.company.updated_at,
      currencyKey: user.company.currency_key,
      avatar: user.company.avatar,
      country: user.company.country,
      description: user.company.description,
      currency: {
        id: user.company.currency.id,
        companyId: user.company.currency.company_id,
        name: user.company.currency.name,
        shortname: user.company.currency.shortname,
        zerosNumber: user.company.currency.zeros_number,
        status: user.company.currency.status,
        type: user.company.currency.type,
        createdAt: user.company.currency.created_at,
        updatedAt: user.company.currency.updated_at,
        network: user.company.currency.network,
      },
    },
  };
  // set(
  //   produce((draft) => {
  //     const { id, language } = res?.data[0];
  //     draft.userApi = res?.data[0];
  //     localStorage.setItem('userId', id);
  //     localStorage.setItem('companyId', res?.data[0].company_id);
  //     i18n.changeLanguage(language.toLowerCase() || '').then();
  //   })
  // );
};
