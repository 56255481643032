import {axios} from "../../../shared/exios";
import { EXCHANGE_MAP_API_URL } from '../../../packages/keycloak-client/constants';
import { IMeta } from '../../../interfaces';
import {TCurrencies, TCurrency} from "../model/types";

export const getCurrencies = async (): Promise<TCurrencies> => {
  try {
    const {data} = await axios.post<{ data: TCurrency[]; meta: IMeta }>(`${EXCHANGE_MAP_API_URL}/partner/api/payment-system-currency/search`, {
      limit: 10000,
      page: 1,
    }, {});
    const fiat: TCurrency[] = []
    data?.data.forEach(curr => {
      if (curr.currency_type === 'fiat' && curr.type === 'cash') {
        fiat.push(curr)
      }
    })
    return (
      {
        data: fiat,
        type: 'fiat',
        meta: data.meta
      })

  } catch {
    return {
      data: [],
      type: 'fiat',
      meta: {
        current_page: 1,
        from: 0,
        last_page: 0,
        path: '',
        per_page: 0,
        to: 0,
        total: 0
      }
    }
  }
}
