// import { ScreenBreakpoints } from "./enums";

// type TBreakpointKeys = keyof typeof ScreenBreakpoints

export const BREAKPOINT = {
  SM: 360,
  MD: 720,
  LG: 992,
  XL: 1280,
} as const

type TBreakpointKeys = keyof typeof BREAKPOINT

export const MEDIA_BREAKPOINT = Object.fromEntries(
  Object.entries(BREAKPOINT).map(([key, value]) => [key, `(min-width: ${value}px)`])
) as Record<TBreakpointKeys, string>