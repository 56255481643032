import styled from 'styled-components';
import { Typography } from 'antd';

export const ChatEmptyStyled = styled.div`
  background-color: #14141f;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
`;

export const EmptyText = styled(Typography.Text)`
  color: rgba(255, 255, 255, 0.5);
  font-size: 20px;
`;

export const ChatListWrapper = styled.div`
  flex: 1 1;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background: inherit;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(71, 71, 85, 1);
    border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: rgba(71, 71, 85, 0.9);
  }
  &::-webkit-scrollbar-button {
    display: none;
  }
`;

export const ScrollSpinWrapper = styled.div`
  height: 30px;
  padding-top: 20px;
  overflow: hidden;
`;
