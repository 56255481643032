import { Alert, Button, Form, Input, InputProps, Tooltip, UploadFile } from 'antd';
import { Upload } from './upload';
import Styles from './index.module.css';
import { SendIcon } from '../../assets/SendIcon';
import { Plus } from '../../assets/PlusIcon';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import FilePreview from './filePreview/FilePreview';
import CloseAlert from './assets/CloseAlert.svg';
import { calcAuthToken } from '../../helpers/calcAuthToken';

interface IChatFormValues {
    message: string;
}

type TChatForm = {
    addMessage: (value: any) => Promise<any>
    createAttachment: (value: any) => Promise<any>
    uploadAttachmentToMinio: (value: any) => Promise<any>
    changeIsUploadInAttachment: (value: any) => Promise<any>
    searchMessages: (value: any) => Promise<any>
    calcEntityId: () => number | string
    entity_type: string
    client_uid: string | number | undefined
    chatType: 'list' | 'deal' | 'admin' | undefined
    typeTabs: string;
    isReferee: boolean;
}

const ChatForm = ({
                      addMessage,
                      createAttachment,
                      uploadAttachmentToMinio,
                      changeIsUploadInAttachment,
                      searchMessages,
                      calcEntityId,
                      entity_type,
                      client_uid,
                      chatType,
                      typeTabs,
                      isReferee
                  }: TChatForm): JSX.Element => {
    const {t} = useTranslation();
    const [form] = Form.useForm<IChatFormValues>();
    const [messageText, setMessageText] = useState<string>('');
    const [files, setFiles] = useState<UploadFile[] | null>(null);

    const [isErrorFileType, setIsErrorFileType] = useState(false);
    const [isErrorFileSize, setIsErrorFileSize] = useState(false);
    const [previewImage, setPreviewImage] = useState('');

    const message = Form.useWatch('message', form);

    const isButtonDisabled = () => {
        // Блокируем кнопку, если нет сообщения и есть файлы
        if (!messageText?.length && files?.length) {
            return true; // Блокируем кнопку
        }
        // Блокируем кнопку, если нет сообщения вообще
        return !messageText?.length;
    };

    const handleDeleteFile = () => {
        setIsErrorFileType(false);
        setIsErrorFileSize(false);
        setFiles(null);
        setPreviewImage('');
    };

    const handleOnSubmit = async () => {
        await form.resetFields();
        handleDeleteFile();
        setMessageText('');

        try {
            const res = await addMessage({
                entity_type: entity_type,
                entity_id: calcEntityId(),
                client_uid: client_uid,
                message: message,
                auth_token: calcAuthToken({chatType: chatType, typeTabs}),
            })

          if (res) {
                if (files) {
                    const index = files[0].name.lastIndexOf('.');
                    const fileName = files[0].name.substring(0, index);
                    const fileExtension = files[0].type?.split('/')[1];
                    await createAttachment({
                        message_id: res,
                        file_name: fileName,
                        file_extension: fileExtension,
                        auth_token: calcAuthToken({chatType: chatType, typeTabs}),
                    }).then(({lastUploadLink, lastCreatedAttachmentId}) => {
                        if (lastUploadLink && lastCreatedAttachmentId) {
                            uploadAttachmentToMinio({
                                upload_link: lastUploadLink,
                                files: files,
                                type: files[0].type,
                            }).then(() => {
                                changeIsUploadInAttachment({
                                    attachment_id: lastCreatedAttachmentId,
                                    auth_token: calcAuthToken({chatType: chatType, typeTabs}),
                                })
                                // TODO: Протестить без этого и убрать
                                // setTimeout(() => {
                                //     if (entity_type && client_uid) {
                                //         searchMessages({
                                //             entity_type: entity_type,
                                //             entity_id: calcEntityId(),
                                //             client_uid: client_uid,
                                //             auth_token: calcAuthToken({chatType: chatType, typeTabs}),
                                //         })
                                //     }
                                // }, 1000)
                            })
                        }
                    })
                }
          }
        } catch (error) {
            console.log('Error:', error);
        }
    };

    const validateFile = (fileList: any[]) => {
        if (fileList && fileList.length === 1) {
            const file = fileList[0];
            const isValidFileType = /\.jpg$|\.pdf$|\.png$/.test(file.name);
            if (!isValidFileType) {
                setIsErrorFileType(true);
                setFiles(null);
                return;
            }
            const isValidFileSize = file.size < 2000000;
            if (!isValidFileSize) {
                setIsErrorFileSize(true);
                setFiles(null);
                return;
            }
            setFiles(fileList);
            setIsErrorFileType(false);
            setIsErrorFileSize(false);
        } else {
            setIsErrorFileType(true);
            setIsErrorFileSize(true);
            setFiles(null);
        }
    };

    const contentAttachPopover = (
        <div className={Styles.popoverContent}>
            <PopoverRowStyled>
                {t('чат.Форматы аттачментов')}
            </PopoverRowStyled>
            <PopoverRowStyled>{t('чат.Максимальный размер аттачмента')}</PopoverRowStyled>
        </div>
    );

    return (
        <>
            {isErrorFileSize && (
                <AlertStyled closable={true}
                             message={t('чат.Файл превышает 2 MB')}
                             type="error"
                             onClose={handleDeleteFile}
                             closeIcon={<img src={CloseAlert} alt={'close'}/>}
                />
            )}
            {isErrorFileType && (
                <AlertStyled
                    closable={true}
                    message={t('чат.Вы можете загрузить только JPG, PNG, PDF')}
                    type="error"
                    onClose={handleDeleteFile}
                    closeIcon={<img src={CloseAlert} alt={'close'}/>}
                />
            )}
            <div className={isReferee ? Styles.containerReferee : Styles.container}>
                <Form
                    form={form}
                    className={Styles.form}
                    onFinish={handleOnSubmit}
                    autoComplete="off"
                >
                    <Tooltip title={contentAttachPopover}>
                        <Form.Item className={Styles.form__item}>
                            <Upload
                                icon={
                                    <Plus
                                        size={16}
                                        color={
                                            isButtonDisabled()
                                                ? '#767687'
                                                : '#FBFBFF'
                                        }
                                    />
                                }
                                onModal={false}
                                onChange={({fileList}) => {
                                    validateFile(fileList);
                                }}
                                disabled={false}
                            />
                        </Form.Item>
                    </Tooltip>
                    <div className={Styles.form__item_input_container}>
                        <FilePreview
                            files={files}
                            handleDeleteFile={handleDeleteFile}
                            setPreviewImage={setPreviewImage}
                            previewImage={previewImage}
                        />
                        <Form.Item
                            name={'message'}
                            className={`${Styles.form__item}`}
                            normalize={(value, prevValue) => {
                                return value.length > 250 ? value.slice(0, 250) : value;
                            }}
                        >
                            <TextAreaWrapper
                                onPressEnter={() => {
                                    handleOnSubmit()
                                }}
                                onChange={(e) => {
                                    setMessageText(e.target.value);
                                }}
                                placeholder={String(t('чат.Введите сообщение'))}
                                autoSize={{maxRows: 6}}
                                disabled={false}
                                showCount={{
                                    formatter: (info) => {
                                        return `${info.count} / 250`;
                                    },
                                }}
                                $messageIsEmpty={!messageText.length}
                            />
                        </Form.Item>
                    </div>
                    <Form.Item className={Styles.form__item}>
                        <Button
                            type="primary"
                            htmlType="submit"
                            icon={
                                <SendIcon
                                    color={
                                        isButtonDisabled()
                                            ? '#767687'
                                            : '#FBFBFF'
                                    }
                                />
                            }
                            size="large"
                            disabled={isButtonDisabled()}
                        />
                    </Form.Item>
                </Form>
            </div>
        </>
    );
};

interface ITextAreWrapper extends InputProps {
    $messageIsEmpty: boolean;
}

const PopoverRowStyled = styled.span`
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.01em;
    text-align: left;
    color: #FBFBFF;
`;

const AlertStyled = styled(Alert)`
    margin: 0 20px;
    background: #2A1215;
    border: 1px solid #58181C;
    color: #FFFFFFD9;
`;

const TextAreaWrapper = styled(Input.TextArea)<ITextAreWrapper>`
    position: relative;

    & textarea {
        background: #14141F;
        border: 1px solid #474755;
        padding-bottom: ${({$messageIsEmpty}) => ($messageIsEmpty ? '0' : '25px')} !important;

        // Стилизация текста в textarea
        color: #FFFFFF !important;
        font-family: Inter, sans-serif !important;
        font-size: 14px !important;
        line-height: 20px !important;

        &::placeholder {
            color: rgba(255, 255, 255, 0.5) !important;
            font-family: Inter, sans-serif !important;
            line-height: 20px !important;
            font-size: 14px !important;
        }

        &::-webkit-scrollbar {
            width: 4px;
        }

        &::-webkit-scrollbar-thumb {
            background: rgba(243, 243, 248, 1);
            border-radius: 0.5rem !important;
        }
    }

    & .ant-input-data-count {
        position: absolute;
        z-index: 2;
        bottom: 0;
        right: 0;
        transform: translate(-10px, -30px);
        color: rgba(118, 118, 135, 1);
    }
`;

export default ChatForm;
