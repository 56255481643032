import Paragraph from 'antd/es/typography/Paragraph';
import { styled } from 'styled-components';

export const SupportEmailWrapperStyled = styled.div`
  color: #74737a;
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
`;


export const CopyableStyled = styled(Paragraph)`
  display: inline;
  font-size: 12px;
  color: #74737a;
  .ant-typography-copy {
    color: #74737a;
    font-size: 12px;
  }

  .ant-typography-copy:hover {
    color: #5297ff;
  }
`;