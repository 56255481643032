import styled from 'styled-components';

export const RatingText = styled.p`
  margin: 0;
  margin-bottom: 12px;
`;
export const RatingInfoRow = styled.div<{ $hasDivider?: boolean }>`
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: space-between;
  border-bottom: ${({ $hasDivider }) => $hasDivider && '1px solid #ffffff1a'};
  padding-bottom: 8px;
`;
export const RatingContainer = styled.div`
  color: #fff;
  display: flex;
  flex-direction: column;
  gap: 6px;
`;
export const RatingInfoResult = styled.label`
  color: #fff;
  margin: 0;
  text-align: left;
`;
export const RatingInfoText = styled.label`
  color: #fff;
  margin: 0;
  text-align: left;
  display: flex;
  flex: 1;
`;
