import { useMessagesState } from '../ChatModule/states/useMessagesState';
import { useEffect, useLayoutEffect } from 'react';
import ChatModule from '../ChatModule/ChatModule';
import { centrifuge, subscribe } from '../../workers/chatWorker';
import { Subscription } from 'centrifuge';

type TChatRefereeWrapper = {
  offerDealId: string | null;
};

const ChatRefereeWrapper = ({ offerDealId }: TChatRefereeWrapper) => {
  const wssChannels = useMessagesState((state) => state.wssChannels);
  const getAdminWSChatToken = useMessagesState(
    (state) => state.getAdminWSChatToken
  );
  const searchMessages = useMessagesState((state) => state.searchMessages);
  const totalMessages = useMessagesState((state) => state.totalMessages);
  const currentPage = useMessagesState((state) => state.currentPage);
  const isFetchingMessages = useMessagesState(
    (state) => state.isFetchingMessages
  );
  const messageLimit = useMessagesState((state) => state.messageLimit);
  const setIsFetchingMessages = useMessagesState(
    (state) => state.setIsFetchingMessages
  );
  const addMessageToMessages = useMessagesState(
    (state) => state.addMessageToMessages
  );
  const messages = useMessagesState((state) => state.messages);
  const createAttachment = useMessagesState((state) => state.createAttachment);
  const addMessage = useMessagesState((state) => state.addMessage);
  const changeIsUploadInAttachment = useMessagesState(
    (state) => state.changeIsUploadInAttachment
  );
  const uploadAttachmentToMinio = useMessagesState(
    (state) => state.uploadAttachmentToMinio
  );
  const addAttachmentToLastMessage = useMessagesState(
    (state) => state.addAttachmentToLastMessage
  );
  const urlParams = new URLSearchParams(window.location.search);
  const tokenFromUrl = urlParams.get('token') as string;

  useEffect(() => {
    getAdminWSChatToken(tokenFromUrl).then();
  }, [tokenFromUrl]);

  useEffect(() => {
    const subscriptions: (Subscription | undefined)[] = [];
    const connectToChannels = async () => {
      if (wssChannels?.length && offerDealId) {
        for (const channelName of wssChannels) {
          const subscription = await subscribe({
            channelName,
            entityId: offerDealId,
            hasReferee: true,
            addMessageToMessages,
            addAttachmentToLastMessage,
          });
          // @ts-ignore
          subscriptions.push(subscription);
        }
      }
    };
    connectToChannels().then();

    return () => {
      subscriptions?.forEach((subscription) => {
        subscription?.unsubscribe();
      });
    };
  }, [wssChannels, centrifuge, offerDealId]);

  useEffect(() => {
    if (messageLimit * currentPage < totalMessages || currentPage === 1) {
      searchMessages({
        entity_type: 'offer_deal',
        entity_id: offerDealId ? offerDealId : '',
        auth_token: `Bearer ${tokenFromUrl}`,
        client_uid: undefined,
      }).then();
    }
  }, [currentPage, totalMessages, messageLimit, messages?.length]);

  useEffect(() => {
    if (centrifuge) {
      centrifuge?.connect();
    }
  }, [centrifuge]);

  return (
    <ChatModule
      isShowTimer={false}
      isClosableChat={false}
      currentChat={{}}
      isReferee={true}
      setIsOpenChat={() => {}}
      isAdmin={true}
      opponent={undefined}
      setIsFetchingMessages={setIsFetchingMessages}
      searchMessages={searchMessages}
      isFetchingMessages={isFetchingMessages}
      messages={messages}
      addMessage={addMessage}
      changeIsUploadInAttachment={changeIsUploadInAttachment}
      createAttachment={createAttachment}
      uploadAttachmentToMinio={uploadAttachmentToMinio}
      calcEntityId={() => (offerDealId ? offerDealId : '')}
      entity_type={'offer_deal'}
      client_uid={undefined}
      chatType={'admin'}
      typeTabs={''}
    />
  );
};

export default ChatRefereeWrapper;
