import { styled } from 'styled-components';
import { Menu, Modal } from 'antd';

export const ModalStyled = styled(Modal)`
  overflow-y: auto;
  .ant-modal-content {
    padding: 0;
  }
  .ant-modal-close {
    display: none;
  }
  &&& {
    .ant-modal-content {
      background: #2b2b36;
    }
  }
`;

export const MenuStyled = styled(Menu)`
  &&& {
    .ant-menu-item {
      padding: 16px;
      height: unset;
      padding: 12px 16px;
      border-bottom: 1px solid #ffffff30;
      line-height: 22px;

      &:hover {
        background-color: #383844;
      }
    }
    .ant-menu-title-content {
      line-height: 22px;
    }
  }
`;
