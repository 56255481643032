import { Skeleton } from "antd";
import { SkeletonNodeProps } from "antd/es/skeleton/Node";
import styled from "styled-components";

type IProps = Omit<SkeletonNodeProps, 'children'>

//TODO Необходимо доработать этот функционал, добавить пропсы ширины и высоты, плюс можно сделать повторяемость скелетона

export const ListItemSkeleton = (props: IProps) => {
  const { active = true, ...restProps } = props

  return <ListItemSkeletonStyled {...restProps} active={active} children={false} />
}

export const ListItemSkeletonStyled = styled(Skeleton.Node)`
  height: 96px;

  &.ant-skeleton {
    display: block;

    .ant-skeleton-image {
      width: 100%;
      height: 100%;
    }
  }
`;