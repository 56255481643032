import styled from 'styled-components';
import { Button, Modal } from 'antd';

export const ModalStyled = styled(Modal)`
  .ant-modal-close {
    svg {
      height: 13px;
      width: 13px;
      margin-bottom: 10px;
    }
    &:hover {
      color: white;
      background-color: rgba(0, 0, 0, 0.06);
    }
  }
`;

export const ModalTitleStyled = styled.div`
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  color: rgba(251, 251, 255, 1);
  margin-bottom: 32px;
`;

export const ModalBodyStyled = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ButtonGroupStyled = styled.div`
  display: flex;
  gap: 16px;
  justify-content: flex-end;
`;

export const ModalDescriptionStyled = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: rgba(222, 222, 227, 1);
  margin-bottom: 16px;
`;

export const ModalDescriptionWithSpaceStyled = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: rgba(222, 222, 227, 1);
  margin-bottom: 32px;
`;

export const CommentBlockStyled = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
  .ant-input {
    min-height: 104px;
    max-height: 280px;
  }
  .ant-input-data-count {
    margin-top: 5px;
    font-size: 12px;
    color: rgba(251, 251, 255, 1);
  }
`;

export const CommentTitleStyled = styled.div`
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 4px;
  color: rgba(251, 251, 255, 1);
`;

export const AcceptButtonStyled = styled(Button)`
  width: 100%;
`;

export const BackButtonStyled = styled(Button)`
  min-width: 180px;
  border: 1px solid rgba(99, 32, 229, 1);
  background-color: transparent;

  &&:hover {
    border: 1px solid rgba(99, 32, 229, 1);
    background: linear-gradient(#2b2b36, #2b2b36) padding-box padding-box,
      linear-gradient(52deg, #7d50ff 0%, #7785ff 100%) border-box border-box;
  }
`;

export const ButtonAsLinkStyled = styled.button`
  color: #1677ff;
  cursor: pointer;
  background-color: transparent;
  border: none;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  padding: 0;
  &:hover,
  &:focus,
  &:active {
    color: #1677ff;
    background-color: transparent;
    &svg {
      color: #1677ff;
    }
  }
`;
