import { FunctionComponent, memo } from 'react';

import ChatListItemOfferStepTimer from './components/Timer/ChatListItemOfferStepTimer';
import { TChatListTranslations } from '../../hooks/useChatListItemTranslations';
import { TIconDefinitions } from '../../hooks/useIconsDefinitions';

import {
  ChatListIconBlock,
  ChatListIconImg,
  ChatListIconColumnItems,
  ChatListIconColumnSubTitle,
  ChatListIconColumnTitle,
  ChatListIconColumn,
} from '../../ChatListItem.style';

type TChatListItemOfferStep = {
  step?: string;
  id?: string;
  createdAt: null | string;
  closedAt: null | string;
  translations: TChatListTranslations;
  iconDefinitions: TIconDefinitions;
  dealId: number | null;
};

type TStatusKey = keyof TIconDefinitions;

const ChatListItemOfferStep: FunctionComponent<TChatListItemOfferStep> = (
  props
) => {
  const {
    step,
    id,
    createdAt,
    closedAt,
    translations,
    dealId,
    iconDefinitions,
  } = props;

  // TODO: Добавить локализацию
  const renderIcon = () => {
    let iconDef:
      | {
          icon: string;
          alt: string;
        }
      | undefined;

    if (!dealId) {
      iconDef = iconDefinitions.temp;
    } else if (step) {
      iconDef = iconDefinitions[step as TStatusKey];
    }

    return iconDef ? (
      <ChatListIconImg src={iconDef.icon} alt={iconDef.alt} />
    ) : null;
  };

  const renderStepText = () => {
    if (!dealId) {
      return <ChatListItemOfferStepTimer createdAt={createdAt} isHideMinutes />;
    } else if (step) {
      return (
        <ChatListIconColumnTitle>
          {iconDefinitions[step as TStatusKey].text}
        </ChatListIconColumnTitle>
      );
    }
    return null;
  };

  const renderSubTitle = () => {
    let text: string | undefined;

    if (!dealId) {
      text = translations.temporaryChat;
    } else if (step && id) {
      text = `${translations.deal} ${id}`;
    }

    if (!text) return null;

    return <ChatListIconColumnSubTitle>{text}</ChatListIconColumnSubTitle>;
  };

  return (
    <ChatListIconBlock>
      <ChatListIconColumn>{renderIcon()}</ChatListIconColumn>
      <ChatListIconColumnItems>
        {renderStepText()}
        {renderSubTitle()}
      </ChatListIconColumnItems>
    </ChatListIconBlock>
  );
};

export default memo(ChatListItemOfferStep);
