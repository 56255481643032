import { IExchangePoint, IMeta } from '../../../interfaces';
import { EXCHANGE_MAP_API_URL } from '../../../packages/keycloak-client/constants';
import { axios } from '../../../shared/exios';
import { TPoints } from '../model/types';

export const getPoints = async (
  uid?: string,
  page?: number
): Promise<TPoints> => {
  try {

    const { data } = await axios.post<{
      data: IExchangePoint[];
      meta: IMeta;
    }>(`${EXCHANGE_MAP_API_URL}/partner/api/exchange-points/search`, {
        filters: [{
          field: "offers.requests.author_uid",
          operator: "=",
          value: uid
        }],
      }
    );
    return {
      data: data?.data,
      meta: data?.meta,
      type: 'point'
    };
  } catch {
    return {
      data: [],
      meta: {
        current_page: page ?? 1,
        from: 0,
        last_page: 0,
        path: '',
        per_page: 0,
        to: 0,
        total: 0
      },
      type: 'point'
    }
  }
};
