import {RouteObject} from "react-router-dom";
import {ROUTER_PATH} from "../../path";
import ChatList from "../../../pages/chat-list/ChatList";

const CHAT_ROUTES: RouteObject[] = [
    {
        path: ROUTER_PATH.ChatRoutes.CHAT_LIST,
        element: <ChatList/>,
    },
    {
        path: ROUTER_PATH.ChatRoutes.CHAT,
        element: <ChatList/>,
    }
];

export {CHAT_ROUTES}
