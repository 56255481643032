import styled from 'styled-components';
import { Dropdown } from 'antd';

export const DropdownStyled = styled(Dropdown)`
  color: white;
  height: 20px;
  width: 15px;
  padding: 5px;
  margin-right: 12px;
  box-sizing: content-box;
  svg {
    font-size: 20px;
  }
`;

