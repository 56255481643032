import {
  useCallback,
  useEffect,
  useMemo,
  useState,
  type FunctionComponent,
  type ReactNode,
} from 'react';
import { Badge, Checkbox, Switch, TabsProps } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import {
  AllFiltersButton,
  ChatListFiltersStyled,
  FilterWrapper,
  TabLabelStyled,
  TabsContainer,
  TabsStyled,
} from './assets/styles';
import { TTabs, useChatListState } from '../../state/useChatListState';
import { CorporateIcon } from 'assets/img/icons/icons';
import { useChatAsideState } from 'widgets/chatAside/model/useChatAsideState';
import { OfferFilters } from 'components/ui/OfferFilters';
import { useAuthState } from 'state/useAuthState';
import { useWindowBreakpointState } from 'state/useWindowBreakpointState';
import { DownOutlinedStyled } from 'pages/my-deals/ui/NavigationTabs/NavigationTabs.styles';
import { getClients } from 'pages/chat-list/api/getClients';
import { getCurrencies } from 'pages/my-deals/api/getCurrencies';
import { getPoints } from 'pages/my-deals/api/getPoints';
import { IChatFilter } from 'pages/chat-list/model/types';

type TRenderTabLabel = {
  label: string;
  count: number;
  icon?: ReactNode;
  onClick: () => void;
};

const ChatListTabLabel: FunctionComponent<TRenderTabLabel> = ({
  label,
  count,
  onClick,
  icon,
}) => {
  return (
    <TabLabelStyled onClick={onClick}>
      {icon}
      {label}
      <Badge color="cyan" count={count} showZero={true} />
    </TabLabelStyled>
  );
};

const ChatListFilters = ({
  typeTabs,
  setTypeTabs,
}: {
  typeTabs: TTabs;
  setTypeTabs: (value: TTabs) => void;
}) => {
  const resetFields = useChatListState((state) => state.resetFields);
  const getPartnersChatsList = useChatListState(
    (state) => state.getPartnersChatsList
  );
  const getPhexChatsList = useChatListState((state) => state.getPhexChatsList);
  // const phexChatsTotalPages = useChatListState(
  //   (state) => state.phexChatsTotalPages
  // );
  // const chatListTotalPages = useChatListState(
  //   (state) => state.chatListTotalPages
  // );
  const setCurrentPage = useChatListState((state) => state.setCurrentPage);
  const deletePartnersChatsList = useChatListState(
    (state) => state.deletePartnersChatsList
  );
  const deletePhexChatsList = useChatListState(
    (state) => state.deletePhexChatsList
  );
  const chatCounts = useChatAsideState((state) => state.chatCounts);
  // const userFinmsApi = useAuthState((state) => state.userFinmsApi);

  const {
    typeDealFilters,
    statusFilters,
    setOptionsFilters,
    points,
    currencies,
    setCurrentFilter,
    setTemporaryFilter,
    temporaryFilter,
    filterPayload,
    clients,
    resetFilters,
    deleteFilter,
  } = useChatListState();

  const { t } = useTranslation();
  const [isFiltersCollapsed, setIsFiltersCollapsed] = useState(true);
  const [filters, setFilters] = useState<IChatFilter[]>([]);

  const onPersonalTabClick = useCallback(() => {
    resetFields();
    setCurrentPage(1);

    deletePhexChatsList();
    getPartnersChatsList();
  }, [resetFields, setCurrentPage, deletePhexChatsList, getPartnersChatsList]);

  const onCorporateTabClick = useCallback(() => {
    resetFields();
    setCurrentPage(1);

    deletePartnersChatsList();
    getPhexChatsList();
  }, [resetFields, setCurrentPage, deletePartnersChatsList, getPhexChatsList]);

  const { userAPI } = useAuthState();

  useEffect(() => {
    if (typeTabs === 'personal') {
      getPartnersChatsList();
      resetFields();
      setCurrentPage(1);
    } else if (typeTabs === 'corporate') {
      getPhexChatsList();
      resetFields();
      setCurrentPage(1);
    }
  }, [
    filterPayload,
    typeTabs,
    onCorporateTabClick,
    onPersonalTabClick,
    resetFilters,
  ]);

  useEffect(() => {
    (async () => {
      try {
        if (!!userAPI.account.uid) {
          const responseOptions = await Promise.all([
            getCurrencies(),
            getPoints(userAPI.account.uid),
            getClients(),
          ]);
          setOptionsFilters(responseOptions);
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [userAPI.account.uid]);

  useMemo(() => {
    const tempFilters = [
      {
        label: t('Направление сделки'),
        options: typeDealFilters,
        nameDefaultProp: t('Все направления'),
        type: 'typeDealFilters',
      },
      {
        label: t('Валюты фиат'),
        options: currencies,
        nameDefaultProp: t('Все валюты'),
        type: 'currencies',
      },
      {
        label: t('Статус сделки'),
        options: statusFilters,
        nameDefaultProp: t('Все статусы'),
        type: 'statusFilters',
      },
      {
        label: t('Пункты обмена'),
        options: points,
        nameDefaultProp: t('Все пункты обмена'),
        type: 'points',
      },
    ];

    if (typeTabs === 'corporate') {
      tempFilters.push({
        label: t('чат.Клиент'),
        options: clients,
        nameDefaultProp: t('Все клиенты'),
        type: 'clients',
      });
    }

    setFilters(tempFilters);
  }, [
    typeTabs,
    points,
    currencies,
    statusFilters,
    t,
    typeDealFilters,
    clients,
  ]);

  const tabs: TabsProps['items'] = [
    {
      label: (
        <ChatListTabLabel
          label={t('Личные')}
          count={chatCounts.personal}
          onClick={onPersonalTabClick}
          icon={<UserOutlined style={{ margin: 0 }} />}
        />
      ),
      key: 'personal',
    },
    {
      label: (
        <ChatListTabLabel
          label={t('Корпоративные')}
          count={chatCounts.corporate}
          onClick={onCorporateTabClick}
          icon={<CorporateIcon />}
        />
      ),
      key: 'corporate',
    },
  ];

  const isTablet = useWindowBreakpointState(
    (state) => state.computed.isScreen720
  );

  const handleCollapse = () => {
    setIsFiltersCollapsed(!isFiltersCollapsed);
  };
  const countFilter = filterPayload?.reduce((acc, el) => {
    if (Array.isArray(el.value)) {
      return acc + el.value.length;
    }

    if (el.operator === '<=') {
      return acc;
    }

    return acc + 1;
  }, 0);

  return (
    <ChatListFiltersStyled className="chat-list-tabs">
      <TabsContainer>
        <TabsStyled
          defaultActiveKey={typeTabs}
          items={tabs}
          onChange={(val) => setTypeTabs(val as TTabs)}
        />
        {isTablet && (
          <AllFiltersButton
            onClick={handleCollapse}
            icon={<DownOutlinedStyled rotate={!isFiltersCollapsed} />}
            type="text"
          >
            {t('Все фильтры')}
          </AllFiltersButton>
        )}
      </TabsContainer>
      <FilterWrapper $isCorporate={typeTabs === 'corporate'}>
        <OfferFilters
          isFiltersCollapsed={isFiltersCollapsed}
          isTemporaryFilter={temporaryFilter}
          onTemporarySwitch={setTemporaryFilter}
          onTemporaryClose={setTemporaryFilter}
          setFilter={setCurrentFilter}
          setValueDate={setCurrentFilter}
          countFilter={countFilter}
          deleteFilter={deleteFilter}
          resetAll={resetFilters}
          filters={filters}
        />
      </FilterWrapper>
    </ChatListFiltersStyled>
  );
};

export default ChatListFilters;
