import {default as baseAxios} from 'axios';
import {CHAT_URL} from "../../packages/keycloak-client/constants";
import jwtDecode from "jwt-decode";
import {TDecodedToken} from "../../state/useAuthState";

export const chatAxios = baseAxios.create({baseURL: CHAT_URL});

chatAxios.interceptors.request.use((config) => {
    console.log('interceptors.request config', config);
    // replace token logic
    // if (getCookieByName('token')) {
    //     config.headers!.Authorization = `Bearer ${getCookieByName('token')}`.replace(
    //         'Bearer ',
    //         ''
    //     );
    // }
    return config;
});

chatAxios.interceptors.response.use(
    (res) => {
        return res;
    },
    async (error) => {
        console.log('interceptors.response error', error);
        console.log('error?.response full', error?.response);
        console.log('error?.response?.status', error?.response?.status, 'error.response.data.message', error.response.data.message);
        if ([401, 403, 500, 422].includes(error?.response?.status)) {
            const originalRequest = error.config;
            // refresh logic
            console.log('originalRequest', originalRequest);
            const decodedOldAuthToken: TDecodedToken = jwtDecode(originalRequest.headers.Authorization);
            console.log('decodedOldAuthToken', decodedOldAuthToken);
        }
        return Promise.reject(error);
    }
);
