import { DatePicker } from 'antd';
import Icon from '@ant-design/icons';
import React from 'react';
import { RangePickerProps } from 'antd/es/date-picker';
import dayjs from 'dayjs';
import { DashCircle } from '../../assets/img/icons/icon';
import './style.css';

const disabledDate: RangePickerProps['disabledDate'] = (current) => {
  return current && current > dayjs().endOf('day');
};

interface ICustomDatePickerProps {
  setValue: (arg: Array<string>) => void;
  valueDate?: string[];
}

export const CustomDatePicker = ({
  setValue,
  valueDate,
}: ICustomDatePickerProps) => (
  <DatePicker.RangePicker
    value={valueDate ? [dayjs(valueDate[0]), dayjs(valueDate[1])] : undefined}
    clearIcon={<Icon component={() => DashCircle()} />}
    disabledDate={disabledDate}
    style={{ width: '100%' }}
    onChange={(val) => {
      setValue(val?.map((el) => el?.toString()) as string[]);
    }}
  />
);
