import { ComponentProps, FunctionComponent, useEffect, useState } from 'react';
import { styled } from 'styled-components';
import dayjs from "dayjs";

import GenericTimer from 'shared/ui/GenericTimer';

type TTimerProps = ComponentProps<typeof ChatListOfferTimer>

type TProps = Omit<TTimerProps, 'deadline'> & {
  className?: string
  createdAt?: string | null
} 

const createdAtToDeadline = (createdAt?: string | null) => {
  if (!createdAt) {
    return
  }

  const date = dayjs(createdAt);
  const offset = dayjs().utcOffset();

  return date
    .add(offset, 'minutes').add(1, 'day')
    .toISOString();
}

// TODO: move file to ui
const ChatListItemOfferStepTimer: FunctionComponent<TProps> = (props) => {
  const [deadline, setDeadline] = useState<string>()
  const { createdAt, ...timerProps } = props

  useEffect(() => {
    if (createdAt) setDeadline(createdAtToDeadline(createdAt))
  }, [createdAt])

  return (
    <ChatListOfferTimer {...timerProps} deadline={deadline} />
  );
};

export default ChatListItemOfferStepTimer;

const ChatListOfferTimer = styled(GenericTimer)`
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  color: #dedee3;

  &.disabled {
    color: #474755;
  }
`
