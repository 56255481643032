import styled from 'styled-components';
import Icon from '@ant-design/icons';
import { Button, Form, Modal } from 'antd';

export const IconCloseStyled = styled(Icon)`
  font-size: 11px;
`;

export const CancelButtonStyled = styled(Button)`
  width: 180px;
`;

export const CustomSubmitButton = styled(Button)`
  width: 60%;
  pointer-events: none;
  ${(props) =>
  props.disabled === false ? 'pointer-events: all' : 'pointer-events: none'};
`;

export const ChangeNicknameModalStyled = styled(Modal)`
  .ant-modal-footer {
    flex-direction: row;
    gap: 16px;
  }
  .ant-form-item-control {
    height: 60px;
  }
  input::placeholder {
    color: #767687;
  }
`;

export const ChangeNicknameModalFormStyled = styled(Form)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 600px;
  margin-top: 24px;
  gap: 24px;
`;

export const ChangeNicknameModalFormInputLabelStyled = styled.label`
  color: #FBFBFF;
`;
