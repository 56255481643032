import { styled } from 'styled-components';

import { MEDIA_BREAKPOINT } from 'shared/constants/media-breakpoints';

export const ChatListStyled = styled.div`
  display: flex;
  height: 100%;
  transition: margin-right 0.5s ease;
`;

export const ChatListLeftStyled = styled.div<{ $isOpen: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 16px;
  width: ${({ $isOpen }) => ($isOpen ? '75%' : '100%')};
  transition: width 0.5s ease;

  @media ${MEDIA_BREAKPOINT.MD} {
    padding: 24px;
  }
`;

export const ChatListRightStyled = styled.div<{ $isOpen: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: ${({ $isOpen }) => ($isOpen ? '25%' : 0)};
  border-left: ${({ $isOpen }) => ($isOpen ? '1px solid #413d50' : 'none')};
  transform: ${({ $isOpen }) =>
    $isOpen ? 'translateX(0)' : 'translateX(100%)'};
  transition: transform 0.5s ease;
`;
