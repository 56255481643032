import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import {
  ChatListLeftStyled,
  ChatListRightStyled,
  ChatListStyled,
} from './assets/styles';
import ChatListHeader from './components/ChatListHeader/ChatListHeader';
import ChatListItems from './components/ChatListItems/ChatListItems';
// import ChatModule from './components/ChatModule/ChatModule';
import { TTabs, useChatListState } from './state/useChatListState';
import { getCookieByName } from '../../shared/helpers/controlCookies';
import ChatListFilters from './components/ChatListFilters/ChatListFilters';
import ChatListWrapper from '../../components/ChatWrappers/ChatListWrapper';
import { ListItemSkeleton } from 'shared/ui/list-item-skeleton';

const ChatList = () => {
  const setCurrentChat = useChatListState((state) => state.setCurrentChat);
  const typeTabs = useChatListState((state) => state.typeTabs);
  const setTypeTabs = useChatListState((state) => state.setTypeTabs);
  const isOpenChat = useChatListState((state) => state.isOpenChat);
  const openChatId = useChatListState((state) => state.openChatId);
  const resetFields = useChatListState((state) => state.resetFields);
  const isLoadingSkeleton = useChatListState(
    (state) => state.isLoadingSkeleton
  );
  const navigate = useNavigate();

  const partnersChatsList = useChatListState(
    (state) => state.partnersChatsList
  );
  const phexChatsList = useChatListState((state) => state.phexChatsList);
  const setIsLoadingSkeleton = useChatListState(
    (state) => state.setIsLoadingSkeleton
  );

  useEffect(() => {
    resetFields();

    return () => resetFields();
  }, [resetFields]);

  useEffect(() => {
    if (openChatId) {
      setCurrentChat();
    }
  }, [typeTabs, partnersChatsList, phexChatsList, openChatId, setCurrentChat]);

  const handleTatyTabs = (value: TTabs) => {
    setIsLoadingSkeleton(true);
    setTypeTabs(value);
  };

  return (
    <ChatListStyled>
      <ChatListLeftStyled $isOpen={isOpenChat}>
        <ChatListHeader onCrossClick={() => navigate(-1)} />
        {!!getCookieByName('phexChatToken') && (
          <ChatListFilters typeTabs={typeTabs} setTypeTabs={handleTatyTabs} />
        )}
        {isLoadingSkeleton ? (
          <WrapSkeletonContainerStyled>
            {Array.from({ length: 6 }).map((el, i) => (
              <ListItemSkeletonStyled key={i} />
            ))}
          </WrapSkeletonContainerStyled>
        ) : (
          <ChatListItems
            typeTabs={typeTabs}
            items={typeTabs === 'personal' ? partnersChatsList : phexChatsList}
          />
        )}
      </ChatListLeftStyled>
      <ChatListRightStyled $isOpen={isOpenChat}>
        <ChatListWrapper />
      </ChatListRightStyled>
    </ChatListStyled>
  );
};

export default ChatList;

export const ListItemSkeletonStyled = styled(ListItemSkeleton)`
  height: 80px;
`;

export const WrapSkeletonContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
