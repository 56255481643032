import { Typography } from "antd";
import styled from "styled-components";

const { Paragraph } = Typography

export const ChatListOperationTitle = styled.div`
  display: flex;
  gap: 5px;
  min-width: 0;
  align-items: center;
  justify-content: start;
`

export const TitleText = styled(Paragraph)`
  font-family: var(--chat-list-font-inter);
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
  color: #fbfbff;

  &.ant-typography {
    margin: 0;
  }
`

export const GavelStyled = styled.img`
  height: 1em;
  width: 1em;
  cursor: pointer;
`
