import { Button } from 'antd';
import { DefaultTFuncReturn } from 'i18next';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import GreyTag from 'components/ui/tags/GreyTag';
import { useWindowBreakpointState } from 'state/useWindowBreakpointState';
import { OfferFilter } from './OfferFilter';
import type { TFilter } from './types';
import { prepareFilterProps } from './utils';

import {
  ButtonsContainerStyled,
  FilterValuesWrap,
  ContainerStyled,
  SwitchContainerStyled,
  StyledSwitch,
  AdditionalActionsAreaStyled,
  MainFiltersStyled,
} from './OfferFilters.styles';
import { SwitchChangeEventHandler } from 'antd/es/switch';

interface OfferFiltersProps {
  className?: string;
  filters: TFilter[];
  resetAll?: () => void;
  setFilter: (
    values: string | number | string[] | number[],
    type: string
  ) => void;
  setValueDate: (values: string[] | string, type: string) => void;
  deleteFilter: (
    type: string,
    value: number[] | string | number | string[]
  ) => void;
  checkboxes?: {
    name: DefaultTFuncReturn;
    value: boolean;
    onChange: (value: boolean) => void;
  }[];
  isFiltersCollapsed: boolean;
  countFilter?: number;
  isTemporaryFilter?: boolean;
  onTemporarySwitch?: SwitchChangeEventHandler;
  onTemporaryClose?: (e: React.MouseEvent<HTMLElement>) => void;
}

export const OfferFilters = (props: OfferFiltersProps) => {
  const { t } = useTranslation();

  const {
    className,
    filters,
    setFilter,
    isFiltersCollapsed,
    isTemporaryFilter,
    resetAll,
    checkboxes,
    onTemporarySwitch,
    onTemporaryClose,
    setValueDate,
    countFilter,
    deleteFilter,
  } = props;

  const isTablet = useWindowBreakpointState(
    (state) => state.computed.isScreen720
  );

  const isDisabled =
    !checkboxes?.some((check) => check.value) &&
    !filters.some((filter) => filter.options.some((opt) => opt.checked)) &&
    !filters.some((filter) => filter.datePicker && filter.valueDate) &&
    !isTemporaryFilter;

  if (isFiltersCollapsed) {
    const filtersToRender = prepareFilterProps(filters, deleteFilter, t);

    return (
      <FilterValuesWrap className={className}>
        {isTemporaryFilter && (
          <GreyTag closable onClose={onTemporaryClose}>
            {t('Только временные')}
          </GreyTag>
        )}
        {filtersToRender.map((f) => (
          <GreyTag key={f.key} closable onClose={f.onClose}>
            {f.label}
          </GreyTag>
        ))}
      </FilterValuesWrap>
    );
  }

  return (
    <ContainerStyled className={className}>
      <MainFiltersStyled>
        {filters.map((filter) => (
          <OfferFilter
            key={`filter-${filter.nameDefaultProp || filter.label}`}
            type={filter.type}
            options={filter.options}
            datePicker={filter.datePicker}
            setFilter={setFilter}
            setValueDate={setValueDate}
            isDisabled={filter.isDisabled}
            search={filter.search}
            isMultiple={filter.isMultiple}
            nameDefaultProp={filter.nameDefaultProp}
            label={filter.label}
            valueDate={filter.valueDate}
          />
        ))}
      </MainFiltersStyled>
      <AdditionalActionsAreaStyled>
        {!isFiltersCollapsed && isTemporaryFilter !== undefined && (
          <SwitchContainerStyled>
            <StyledSwitch
              $checked={isTemporaryFilter}
              checked={isTemporaryFilter}
              onChange={onTemporarySwitch}
            />
            <p> {t('Только временные')}</p>
          </SwitchContainerStyled>
        )}
        {isTablet && resetAll && (
          <ButtonsContainerStyled>
            <ButtonWrapperStyled
              type={'text'}
              disabled={isDisabled}
              onClick={resetAll}
            >
              {t('Сбросить фильтры')}
              {!!countFilter && (
                <CountWrapperStyled>{countFilter}</CountWrapperStyled>
              )}
            </ButtonWrapperStyled>
          </ButtonsContainerStyled>
        )}
      </AdditionalActionsAreaStyled>
    </ContainerStyled>
  );
};

const CountWrapperStyled = styled.span`
  font-size: 10px;
  margin-left: 4px;
  min-height: 14px;
  min-width: 18px;
  display: inline-block;
  border-radius: 20px;
  background: linear-gradient(90deg, #088473 0%, #0ec6ad 100%);
  color: white;
  box-sizing: content-box;
  padding: 1px;
`;

const ButtonWrapperStyled = styled(Button)`
  &&& {
    color: var(--deals-white-shaded);
    padding-right: 0px;
    &:not(:disabled) {
      &:hover,
      &:focus {
        color: var(--deals-white);
      }
    }
  }
`;
