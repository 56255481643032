import { Form, Input, notification } from 'antd';
import React, { ChangeEvent, useState } from 'react';
import { CloseIcon } from '../../../../assets/img/icons/icons';
import { t } from 'i18next';
import { axios } from '../../../../shared/exios';
import { EDENEX_ACCOUNT_URL } from "../../../../packages/keycloak-client/constants";
import { useCookiesCustom } from '../../../../hooks/useCookieCustom';
import {
  CancelButtonStyled, ChangeNicknameModalFormInputLabelStyled,
  ChangeNicknameModalFormStyled,
  ChangeNicknameModalStyled,
  CustomSubmitButton,
  IconCloseStyled,
} from './styles';
import { nicknameValidator } from 'shared/helpers/validators';
import { InputWithTooltip } from 'components/ui/InputWithTooltip';
import Flex from 'shared/ui/Flex';

interface IChangeEmailModal {
  refetch: ()=> void;
  showModalNickname: boolean;
  setShowModalNickname: React.Dispatch<React.SetStateAction<boolean>>;
}

const ChangeNicknameModal = ({
                               showModalNickname,
                               setShowModalNickname,
                               refetch
                          }: IChangeEmailModal): JSX.Element => {
  const {cookie} = useCookiesCustom();
  const [form] = Form.useForm();
  const [btnSubmitDisabled, setBtnSubmitDisabled] = useState(false);
  const [nicknameValidState, setNicknameValidState] = useState<string[]>([]);
  const [nickname, setNickname] = useState<string>('');

  const handleCancelNicknameModal = () => {
    setShowModalNickname(false);
    setBtnSubmitDisabled(true);
  };
  const handleNickname = (e: ChangeEvent<HTMLInputElement>) => {
    setNickname(e.target.value);
  };

 
  const handleFieldValueChange = () => {
    form.validateFields().then();
  };

  const postFormData = async () => {
    try {
      await axios.patch(
        `${EDENEX_ACCOUNT_URL}/edenex-account/api/update-nickname`,
        {
          nickname: form.getFieldValue('newNickname'),
        },
        {
          headers: {
            Authorization: `Bearer ${cookie.token?.replace('Bearer ', '')}` || axios.defaults.headers['Authorization'],
          },
        },
      ).then((res) => {
        if (res) {
          setTimeout(()=> {
            notification.success({
              message: (
                <span
                  style={{
                    fontSize: '16px',
                    fontWeight: '700',
                    color: '#FBFBFF',
                  }}>
              {t('notifications.nicknameChanged')}
            </span>
              ),
              style: { background: '#2B2B36' },
              placement: 'topRight',
              duration: 2,
            });
          }, 500)
          refetch();
        }
      });
    } catch (e: any) {
      const errors = (str: string) => {
        if (str.includes('A moratorium has been imposed on the user')) {
          return t('На данного пользователя наложен мораторий');
        } else {
          return t('Никнейм уже занят');
        }
      };

      if (e.response.status === 422) {
        form.setFields([
          {
            name: 'newNickname',
            errors: [errors(e?.response?.data.message)],
          },
        ]);

      }
    }
  };

  return (
      <ChangeNicknameModalStyled
        title={t('titles.changeNickname')}
        open={showModalNickname}
        closeIcon={
          <IconCloseStyled
            component={CloseIcon}
            onClick={() => setShowModalNickname(false)}
          />
        }
        okText={t('buttons.change').toString()}
        cancelText={t('buttons.cancel').toString()}
        width={560}
        centered
        maskStyle={{
          backdropFilter: 'blur(9px)',
        }}
        closable={true}
        destroyOnClose={true}
        footer={null}
      >
        <ChangeNicknameModalFormStyled
          form={form}
          onValuesChange={handleFieldValueChange}
          preserve={false}
          onFinish={postFormData}
          name='basic'
          autoComplete='off'
          layout={'vertical'}
          requiredMark={false}
          onFieldsChange={() => setBtnSubmitDisabled(false)}
        >
          <Form.Item
            label={
              <ChangeNicknameModalFormInputLabelStyled>
                {t('inputs.labels.enterNewNickname')}
              </ChangeNicknameModalFormInputLabelStyled>
            }
            name='newNickname'
            rules={[
              {
                required: true,
                validator: (_, value) =>
                  nicknameValidator(value, setNicknameValidState),
                message: `${t('Никнейм не соответствует требованиям')}`,
              },
            ]}
          >
            <InputWithTooltip 
              onChange={handleNickname} 
              validState={nicknameValidState} 
              type='nickname'
            />
          </Form.Item>
          <Flex>
            <Form.Item noStyle>
              <CustomSubmitButton
                key="submit"
                type="primary"
                htmlType="submit"
                disabled={btnSubmitDisabled}

              >
                {t('buttons.change')}
              </CustomSubmitButton>
            </Form.Item>
            <CancelButtonStyled
              key="cancel"
              className={'CancelButton'}
              onClick={handleCancelNicknameModal}
            >
              {t('buttons.cancel')}
          </CancelButtonStyled>
        </Flex>
        </ChangeNicknameModalFormStyled>
      </ChangeNicknameModalStyled>
  );
};

export { ChangeNicknameModal };
