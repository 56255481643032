import { Col, Input, Row, Space, Tooltip } from 'antd';
import styled from 'styled-components';
import React, { ChangeEvent } from 'react';
import { CheckOutlined, PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

type TInputWithTooltip = {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  validState: string[];
  type: 'password' | 'nickname';
};

export const InputWithTooltip = ({
  onChange,
  validState,
  type,
  ...props
}: TInputWithTooltip) => {
  const { t } = useTranslation();

  return (
    <Tooltip
      trigger={['focus']}
      title={
        type === 'password' ? (
          <PasswordTooltipContent validState={validState} />
        ) : (
          <NicknameTooltipContent validState={validState} />
        )
      }
      placement="leftTop"
      color="#2b2b36"
    >
      {type === 'password' ? (
        <Input.Password
          placeholder={`${t('authorizationForms.password')}`}
          onChange={onChange}
          {...props}
        />
      ) : (
        <Input
          placeholder={`${t('authorizationForms.nickname')}`}
          onChange={onChange}
          {...props}
        />
      )}
    </Tooltip>
  );
};

const NicknameTooltipContent = ({ validState }: { validState: string[] }) => {
  const { t } = useTranslation();

  return (
    <TooltipWrapper>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Space direction="vertical" size={9}>
            <TooltipItem>
              <StatusIcon valid={validState.includes('lengthValid')} />
              {t('authorizationForms.fromTo', { from: 3, to: 255 })}
            </TooltipItem>
            <TooltipItem>
              <StatusIcon valid={validState.includes('langValid')} />
              {t('authorizationForms.onlyLatinCharacters')}
            </TooltipItem>
            <TooltipItem>
              <StatusIcon valid={validState.includes('symbolValid')} />
              {`${t('Допускаются символы двоеточие')}`}
              <br />- _ . 0-9
            </TooltipItem>
          </Space>
        </Col>
      </Row>
    </TooltipWrapper>
  );
};

const PasswordTooltipContent = ({ validState }: { validState: string[] }) => {
  const { t } = useTranslation();

  return (
    <TooltipWrapper>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <TooltipHeader>
            {t('authorizationForms.passwordMustContain')}
          </TooltipHeader>
        </Col>
        <Col span={24}>
          <Space direction="vertical" size={9}>
            <TooltipItem>
              <StatusIcon valid={validState.includes('lengthValid')} />
              {t('authorizationForms.fromTo', { from: 8, to: 32 })}
            </TooltipItem>
            <TooltipItem>
              <StatusIcon valid={validState.includes('registerValid')} />
              {t('authorizationForms.lowercaseUppercaseLetter')}
            </TooltipItem>
            <TooltipItem>
              <StatusIcon valid={validState.includes('langValid')} />
              {t('authorizationForms.onlyLatinCharacters')}
            </TooltipItem>
            <TooltipItem>
              <StatusIcon valid={validState.includes('numValid')} />
              {t('authorizationForms.fromOneDigit')}
            </TooltipItem>
            <TooltipItem>
              <StatusIcon valid={validState.includes('symbolValid')} />
              {`${t('authorizationForms.fromOneCharacter')}`}
              <br />
              {`() " " «» | . ,: ; ? ! * + % - < > @ [ ] { } _ {} $ # ~`}
            </TooltipItem>
          </Space>
        </Col>
      </Row>
    </TooltipWrapper>
  );
};

const StatusIcon = ({ valid }: { valid: boolean }) => {
  return valid ? <CheckOutlinedStyled /> : <PlusOutlinedStyled />;
};

const CustomPasswordInputLabelStyled = styled.div`
  color: #fbfbff;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16.94px;
  width: 100%;
`;

const CustomPasswordInputStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;

const CustomPasswordInputInnerStyled = styled(Input.Password)`
  width: 100%;
`;

const TooltipHeader = styled.span`
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  color: #fbfbff;
`;
const TooltipItem = styled.span`
  display: flex;
  align-items: flex-start;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  gap: 8px;
  line-height: 15px;
  white-space: pre-wrap;
  color: #fbfbff;
`;

const PlusOutlinedStyled = styled(PlusOutlined)`
  font-size: 16px;
  rotate: 45deg;
  color: #f16063;
`;
const CheckOutlinedStyled = styled(CheckOutlined)`
  font-size: 16px;
  color: #58bf92;
`;
const TooltipWrapper = styled.div`
  background-color: #2b2b36;
`;
