import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  ChatHeaderInfoTempTimerStyled,
  TimerContainerStyled,
} from './assets/styles';
import dayjs from 'dayjs';
import { useMemo } from 'react';

type TChatHeaderInfo = {
  created_at: string | null;
};

const ChatHeaderInfo = ({ created_at }: TChatHeaderInfo) => {
  const { t } = useTranslation();

  const calcDeadline = () => {
    if (created_at) {
      const date = dayjs(created_at);
      const offset = dayjs().utcOffset();
      return date.add(offset, 'minutes')?.add(1, 'day').toISOString();
    } else return undefined;
  };

  const renderChatInfo = useMemo(() => {
    const deadLine = calcDeadline();

    if (created_at) {
      return (
        <Tooltip
          trigger={'hover'}
          title={t('чат.Таймер тултип')}
          placement="bottomLeft"
        >
          <TimerContainerStyled>
            <ChatHeaderInfoTempTimerStyled
              value={deadLine}
              format={`H ${t('чат.ч')} : m`}
            />
            <span>
              {t('чат.м')} {t('чат.временный чат')}
            </span>
          </TimerContainerStyled>
        </Tooltip>
      );
    } else {
      return <></>;
    }
  }, [created_at]);

  return <>{renderChatInfo}</>;
};

export default ChatHeaderInfo;
