import { useNavigate } from 'react-router-dom';
import { Button, Typography } from 'antd';
import Icon from '@ant-design/icons';
import { ReactComponent as Chupacabra } from '../../../assets/img/chupacabra.svg';
import { useTranslation } from 'react-i18next';

const { Title } = Typography;

const NotFoundPage = (): JSX.Element => {
  const navigate = useNavigate();
  const {t} = useTranslation();
  const eventHandler = () => {
    navigate(`/`);
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        background: 'rgb(20, 20, 31)',
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '50px',
          marginBottom: '50px',
        }}
      >
        <div>
          <Title
            className={'apply-font-chava-reg'}
            level={1}
            style={{
              fontSize: '200px',
              lineHeight: '120%',
              margin: 0,
            }}
          >
            404
          </Title>

          <Title
            level={1}
            style={{
              margin: 0,
            }}
          >
            {t('pages.pageDoesntFound')}
          </Title>
        </div>

        <Icon component={Chupacabra} style={{ fontSize: '350px' }} />
      </div>

      <Button type="primary" onClick={eventHandler}>
        {t('buttons.backToHome')}
      </Button>
    </div>
  );
};

export default NotFoundPage;
